import React from 'react';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import { Link } from 'react-router-dom';
import { getCategoryLink } from '../../utils/category-utils';
import './index.less';
import { ICategoriesState } from '../../reducers/home-reducers';

interface ICategorySelectorMenuProps {
	categories: ICategoriesState;
}

export class CategorySelectorMenu extends React.Component<ICategorySelectorMenuProps> {
	private dropdown: Dropdown | undefined;

	_handleClick = () => {
		this.dropdown.hide();
	};

	render() {
		const {
			categories: { categories },
		} = this.props;
		const contentClass = 'dropdown-content-wide';
		const listItems: JSX.Element[] = [];
		let displayCategories = categories.defaultCategories;
		if (!categories.isFetching && categories.categories) {
			displayCategories = categories.categories;
		}

		if (displayCategories) {
			displayCategories.forEach(c => {
				listItems.push(
					// TODO: Fix this the next time the file is edited.
					// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
					<li key={`categories-menu-${c.id}`} onClick={this._handleClick}>
						<Link to={getCategoryLink(c)}>{c.title}</Link>
					</li>
				);
			});
		}

		if (categories.isFetching) {
			listItems.push(
				// TODO: Fix this the next time the file is edited.
				// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
				<li key="menu-processing" onClick={this._handleClick}>
					Loading&hellip;
				</li>
			);
		}

		return (
			<div className="category-selector-menu">
				<Dropdown className="dropdown" ref={ref => (this.dropdown = ref)}>
					<DropdownTrigger className="dropdown-top">
						Browse
						<span className="dropdown-arrow" />
					</DropdownTrigger>
					<DropdownContent className={contentClass}>
						<ul>{listItems}</ul>
					</DropdownContent>
				</Dropdown>
			</div>
		);
	}
}
