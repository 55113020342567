import React from 'react';
import { Provider } from 'react-redux';
import Helmet from 'react-helmet';

export function getViewRoot(View, store) {
	return (
		<div style={{ height: '100%' }}>
			<Helmet titleTemplate="%s - Faithlife TV" />
			<Provider store={store}>{View}</Provider>
		</div>
	);
}
