import React from 'react';
import './index.less';

interface ICornerRibbonProps {
	text: string;
	type?: 'item' | 'compact';
}

export class CornerRibbon extends React.Component<ICornerRibbonProps> {
	render() {
		const { text, type } = this.props;
		const shortText = text.length < 5 ? 'short' : '';

		return (
			<div className={`corner-ribbon ${type}`}>
				<div className={`text ${shortText}`}>{text}</div>
			</div>
		);
	}
}
