import groupby from 'lodash.groupby';
import { FaithlifeTVClients } from '../clients/clients';
import { ITvClient } from '../clients/tv';
import { IRootState } from '../reducers';
import { ICategoryState } from '../reducers/category-reducers';
import { ICategoriesState } from '../reducers/home-reducers';
import { IItem } from '../reducers/item-reducers';
import {
	clearCategoriesType,
	getCategoriesTypes,
	getCategoryTypes,
	getKidsCategoriesTypes,
	getDefaultCategoriesTypes,
	getItemsInCategoryTypes,
	IActionDispatch,
} from './action-types';

const kidsCategoryId = '504';

export const dynamicCategories = [
	{ id: 'watchlist', token: 'watchlist', title: 'My Watchlist' },
	{ id: 'recent', token: 'recent', title: 'Recently Watched' },
];

const defaultCategories = [
	...dynamicCategories,
	{
		id: '537',
		title: 'Faithlife Originals',
		token: 'faithlife-originals',
		ownerId: null,
		parentId: null,
		sortKey: 100,
	},
	{
		id: '505',
		title: 'Featured',
		token: 'featured',
		ownerId: null,
		parentId: null,
		sortKey: 200,
	},
	{
		id: '499',
		title: 'Live Streams',
		token: 'live-streams',
		ownerId: null,
		parentId: null,
		sortKey: 400,
	},
	{
		id: '558',
		title: 'Recently Added',
		token: 'recently-added',
		ownerId: null,
		parentId: null,
		sortKey: 500,
	},
];

export function clearCategories() {
	return {
		type: clearCategoriesType,
	};
}

export function getCategories(reload = false, isKids = false) {
	return (dispatch: IActionDispatch, getState: () => IRootState) => {
		const {
			categories: { categories, kidsCategories },
		}: { categories: ICategoriesState } = getState();
		const updateCategories = !isKids && !categories.isProcessing && !categories.categories;
		const updateKidsCategories = isKids && !kidsCategories.isProcessing && !kidsCategories.categories;
		if (reload || updateCategories || updateKidsCategories) {
			return dispatch(doGetCategories(isKids));
		}
		return Promise.resolve({
			result: categories.categories,
			type: isKids ? getKidsCategoriesTypes.success : getCategoriesTypes.success,
		});
	};
}

export function updateCategories(isKids = false) {
	return (dispatch: IActionDispatch, getState: () => IRootState) => {
		const {
			categories: { categories, kidsCategories },
		}: { categories: ICategoriesState } = getState();
		const updateCategories = !isKids && !categories.isProcessing && !categories.categories;
		const updateKidsCategories = isKids && !kidsCategories.isProcessing && !kidsCategories.categories;
		if (updateCategories || updateKidsCategories) {
			return dispatch(doGetCategories(isKids));
		}

		// only update 'watchlist' and 'recent' category
		const categoriesToUpdate = isKids ? kidsCategories.categories : categories.categories;
		if (categoriesToUpdate) {
			for (const category of dynamicCategories) {
				const categoryToUpdateExists = categoriesToUpdate.find(x => x.id === category.id);
				if (!categoryToUpdateExists) {
					return dispatch(doGetCategories(isKids));
				}
			}
		}

		return Promise.all(dynamicCategories.map(category => dispatch(getItemsInCategory(category.id, null, isKids))));
	};
}

export function getCategory(categoryId?: string, reload = false, isKids = false) {
	return (dispatch: IActionDispatch, getState: () => IRootState) => {
		const { category }: { category: ICategoryState } = getState();
		if (!reload && ((category.category && category.category.id === categoryId) || category.isFetching)) {
			return Promise.resolve();
		}
		if (categoryId) {
			return dispatch(doGetCategory({ categoryId }, isKids));
		}
		return Promise.resolve(category);
	};
}

export function getCategoryByToken(categoryToken, reload = false, isKids = false) {
	return (dispatch: IActionDispatch, getState: () => IRootState) => {
		const { category }: { category: ICategoryState } = getState();
		if (!reload && ((category.category && category.category.token === categoryToken) || category.isFetching)) {
			return Promise.resolve();
		}
		if (categoryToken) {
			return dispatch(doGetCategory({ categoryToken }, isKids));
		}
		return Promise.resolve(category);
	};
}

export function getItemsInCategory(categoryId: string, pageToken: string | null, isKids = false) {
	return {
		isKids,
		types: getItemsInCategoryTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => doGetItemsInCategoryAsync(tvClient, categoryId, pageToken, isKids),
		canCancel: true,
		cancellationGroup: `items-page-${categoryId}`,
	};
}

export function getDefaultCategories() {
	return {
		types: getDefaultCategoriesTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => doGetCategoriesItemsAsync(tvClient, defaultCategories),
	};
}

export function initializeDefaultCategories() {
	return {
		types: getDefaultCategoriesTypes,
		promise: Promise.resolve(defaultCategories),
	};
}

function doGetCategories(isKids = false) {
	return {
		types: isKids ? getKidsCategoriesTypes : getCategoriesTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => doGetCategoriesAsync(tvClient, isKids),
		canCancel: true,
		cancellationGroup: isKids ? 'kids-categories' : 'categories',
	};
}

async function getAllCategoriesAsync(tvClient: ITvClient, isKids = false) {
	const parentId = isKids ? kidsCategoryId : null;
	const categories = await tvClient.getCategories(['videos', 'series'], true, parentId);
	if (!Array.isArray(categories)) {
		throw new Error('Error getting categories');
	}

	categories.unshift({
		id: 'recent',
		token: 'recent',
		title: 'Recently Watched',
	});

	if (!isKids) {
		let indexToInsertStore = categories.findIndex(x => x.token === 'live-streams');
		indexToInsertStore = indexToInsertStore < 0 ? categories.length : indexToInsertStore;
		categories.splice(indexToInsertStore, 0, {
			id: 'store',
			token: 'store',
			title: 'Store',
		});

		categories.unshift({
			id: 'watchlist',
			token: 'watchlist',
			title: 'My Watchlist',
		});
	}

	return categories;
}

async function doGetCategoriesAsync(tvClient: ITvClient, isKids = false) {
	const parentId = isKids ? kidsCategoryId : null;
	const categories = await getAllCategoriesAsync(tvClient, isKids);
	return await doGetCategoriesItemsAsync(tvClient, categories, parentId);
}

async function doGetCategoriesItemsAsync(tvClient: ITvClient, categories: any[], parentId?: string | null) {
	const response = await tvClient.getItemsByCategory(
		categories.map(category => category.id),
		true,
		20,
		parentId
	);

	const categoryItemsById = response && response.categories ? groupby(response.categories, 'categoryId') : {};

	return Promise.resolve(
		categories
			.map(category => {
				const categoryItemsArray = categoryItemsById[category.id];
				const { items, nextPage }: { items: IItem[]; nextPage: string } =
					categoryItemsArray && categoryItemsArray.length ? categoryItemsArray[0] : {};
				return { ...category, items, nextPage };
			})
			.filter(
				category =>
					category &&
					((category.items && category.items.length) || (category.subcategories && category.subcategories.length))
			)
	);
}

function doGetCategory(
	{ categoryId, categoryToken }: { categoryId?: string; categoryToken?: string },
	isKids: boolean = false
) {
	return {
		categoryId,
		categoryToken,
		types: getCategoryTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => doGetCategoryAsync(tvClient, { categoryId, categoryToken }, isKids),
		canCancel: true,
		cancellationGroup: 'category',
	};
}

async function doGetCategoryAsync(
	tvClient: ITvClient,
	{ categoryId, categoryToken }: { categoryId?: string; categoryToken?: string },
	isKids = false
) {
	const categories = await getAllCategoriesAsync(tvClient, isKids);
	const category = categories
		.reduce((result, category) => [...result, ...[category, ...(category.subcategories || [])]], [])
		.find(categoryToken ? x => x.token === categoryToken : x => x.id === categoryId);
	const parentId = isKids ? kidsCategoryId : null;

	if (category) {
		category.items = [];
		let pageToken;
		do {
			const response = await tvClient.getItemsInCategory(category.id, pageToken, true, false, 1000, parentId);
			category.items.push(...response.items);
			pageToken = response.nextPage;
		} while (pageToken);
	}
	return category;
}

async function doGetItemsInCategoryAsync(
	tvClient: ITvClient,
	categoryId: string,
	pageToken: string | null,
	isKids = false
) {
	const parentId = isKids ? kidsCategoryId : null;
	const response = await tvClient.getItemsInCategory(categoryId, pageToken, true, false, 20, parentId);
	const { items, nextPage } = response;
	return { categoryId, items, fromPage: pageToken, nextPage };
}
