export function activate({ components }, activationArgs) {
	return Promise.all(components.map(c => activateComponent(c, activationArgs)));
}
function activateComponent(c, activationArgs) {
	if (c.activate) {
		return c.activate(...activationArgs);
	} else if (c.WrappedComponent && c.WrappedComponent.activate) {
		return c.WrappedComponent.activate(...activationArgs);
	} else if (typeof c.load === 'function') {
		return c.load().then(module => {
			if (module?.default) {
				return activateComponent(module.default, activationArgs);
			}
		});
	}
	return Promise.resolve();
}
