import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { rootReducer } from './reducers';

export function configureStore(initialState, middlewareApi) {
	const composeEnhancers =
		typeof window === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
			? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
			: compose;

	const createStoreWithMiddleware: any = composeEnhancers(
		applyMiddleware(thunkMiddleware, createCancelWhenRunningMiddleware(), createPromiseMiddleware(middlewareApi))
	)(createStore);

	return createStoreWithMiddleware(rootReducer, initialState);
}

function createPromiseMiddleware(...callbackArgs) {
	return function promiseMiddleware() {
		return next => action => {
			const { promise, canCancel, types, ...rest } = action;
			if (!promise) {
				return next(action);
			}

			next({ ...rest, type: types.processing });

			let cancel;
			const cancelPromise = new Promise((resolve, reject) => {
				cancel = reject;
			});

			const wrapped = Promise.race([
				cancelPromise,
				Promise.resolve().then(() => {
					if (typeof promise === 'function') {
						return promise(...callbackArgs);
					}

					return promise;
				}),
			]).then(
				result => next({ ...rest, result, type: types.success }),
				error => {
					if (error !== 'canceled') {
						next({ ...rest, error, type: types.error });
					}
				}
			);

			if (canCancel) {
				wrapped.cancel = () => cancel('canceled');
			}

			return wrapped;
		};
	};
}

function createCancelWhenRunningMiddleware() {
	const runningActions = {};

	return function cancelWhenRunningMiddleware() {
		return next => action => {
			const group = action.cancellationGroup;

			if (!group) {
				return next(action);
			}

			if (!action.canCancel) {
				throw new Error('Actions must be cancelable to use cancellation groups');
			}

			const runningAction = runningActions[group];
			if (runningAction) {
				runningAction.cancel();
			}

			const currentAction = next(action);
			runningActions[group] = currentAction;

			const remove = () => {
				if (runningActions[group] === currentAction) {
					runningActions[group] = null;
				}
			};

			return currentAction.then(remove, remove);
		};
	};
}
