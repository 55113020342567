/* eslint-disable import/export */
import * as redux from 'react-redux';

export function connect<TStateProps = {}, TDispatchProps = {}, TOwnProps = {}, State = {}>(
	mapStateToProps: redux.MapStateToPropsParam<TStateProps, TOwnProps, State>
): any;
export function connect<TStateProps = {}, TDispatchProps = {}, TOwnProps = {}, State = {}>(
	mapStateToProps: redux.MapStateToPropsParam<TStateProps, TOwnProps, State>,
	mapDispatchToProps: redux.MapDispatchToPropsParam<TDispatchProps, TOwnProps>
): any;
export function connect<TStateProps = {}, TDispatchProps = {}, TOwnProps = {}, State = {}>(
	mapStateToProps: redux.MapStateToPropsParam<TStateProps, TOwnProps, State>,
	mapDispatchToProps?: redux.MapDispatchToPropsParam<TDispatchProps, TOwnProps>
): any {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	return redux.connect(mapStateToProps, mapDispatchToProps!);
}
