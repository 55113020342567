import React from 'react';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import { Link } from 'react-router-dom';
import { manageSubscriptionUrl } from '../../data/products';
import './index.less';
import { IUser } from '../../reducers/auth-reducers';
import { ISubscriptionState } from '../../reducers/subscription-reducers';

interface IAccountWidgetProps {
	user: IUser;
	location: URL;
	subscription: ISubscriptionState;
}

export class AccountWidget extends React.Component<IAccountWidgetProps> {
	private dropdown: Dropdown | undefined;
	private signOutForm?: HTMLFormElement | null;

	signOut(event: React.SyntheticEvent) {
		event.preventDefault();
		if (this.signOutForm) {
			this.signOutForm.submit();
		}
	}

	hideSignInLink(currentLocation: string) {
		const hiddenLocations = ['/signin', '/register', '/forgotpassword'];
		return hiddenLocations.includes(currentLocation);
	}

	_handleClick = () => {
		this.dropdown.hide();
	};

	render() {
		const {
			user,
			location: { pathname },
			subscription: { hasSubscription },
		} = this.props;
		const contentClass = hasSubscription ? 'dropdown-content-wide' : 'dropdown-content-narrow';

		if (user.id === '-1') {
			return this.hideSignInLink(pathname) ? (
				<div className="account-widget" />
			) : (
				<div className="account-widget">
					<Link to="/signin">Sign In</Link>
				</div>
			);
		}

		return (
			<div className="account-widget">
				<Dropdown className="dropdown" ref={ref => (this.dropdown = ref)}>
					<DropdownTrigger className="dropdown-top">
						{user.alias || user.name}
						<span className="dropdown-arrow" />
					</DropdownTrigger>
					<DropdownContent className={contentClass}>
						{/* TODO: Fix this the next time the file is edited. */}
						{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
						<ul onClick={this._handleClick}>
							{!hasSubscription ? (
								<li key="getFreeTrial">
									<Link to="/get-free-trial">Learn More</Link>
								</li>
							) : (
								false
							)}
							<li key="waysToWatch">
								<Link to="/watch-anywhere">Ways to Watch</Link>
							</li>
							{hasSubscription ? (
								<li>
									<a href={manageSubscriptionUrl}>Manage Subscription</a>
								</li>
							) : (
								false
							)}
							<li>
								<a href="https://support.faithlife.com/hc/en-us/categories/360000551632">Support</a>
							</li>
							<li>
								<a href="/auth/signout" onClick={event => this.signOut(event)}>
									Sign Out
								</a>
							</li>
						</ul>
					</DropdownContent>
				</Dropdown>
				<form ref={ref => (this.signOutForm = ref)} style={{ display: 'none' }} action="/auth/signout" method="post" />
			</div>
		);
	}
}
