const price = '$4.99/mo';

interface ILandingPage {
	token: string;
	categoryIds: readonly string[];
	bannerTitle: string;
	bannerSubtitle: string;
	imageUrl: string;
	shouldIndex: boolean;
	title?: string;
	description?: string;
}

export const categoryLandingPages: ILandingPage[] = [
	{
		token: 'christian-family-entertainment-videos',
		categoryIds: [
			'520', // family
			'516', // comedy
			'518', // drama
			'504', // kids
		],
		bannerTitle: 'Faith and Family Friendly',
		bannerSubtitle: `Access hundreds of hours of Christian family-friendly entertainment for ${price}.`,
		imageUrl: 'https://files.logoscdn.com/v1/files/3686944/content.jpg?signature=8PtSFjjvmlFoHt-4HWltaXqhqPA',
		shouldIndex: true,
	},
	{
		token: 'bible-study-videos',
		categoryIds: ['510'],
		bannerTitle: 'Bible Study on the Big Screen',
		bannerSubtitle: `Grow in the light with hours of Bible study video for ${price}.`,
		imageUrl: 'https://files.logoscdn.com/v1/files/3686949/content.jpg?signature=X1_lxFoSPJXVOiSmz_3jKf4qiD8',
		shouldIndex: true,
	},
	{
		token: 'christian-childrens-videos',
		categoryIds: [
			'504', // kids
		],
		bannerTitle: 'Christian Children’s Videos',
		bannerSubtitle: `Enjoy hundreds of hours of kid-friendly entertainment for ${price}.`,
		imageUrl: 'https://files.logoscdn.com/v1/files/3686946/content.jpg?signature=1SBGZ0lnoICmYJF3eQtRmRX39tA',
		shouldIndex: true,
	},
	{
		token: 'christian-history-documentary-videos',
		categoryIds: [
			'511', // biography
			'522', // history
			'517', // documentary
		],
		bannerTitle: 'Christian History Documentaries',
		bannerSubtitle: `Hundreds of history and biography documentaries for ${price}.`,
		imageUrl: 'https://files.logoscdn.com/v1/files/3686945/content.jpg?signature=rNbr1Tz35DCvL1PbgChY6F802T8',
		shouldIndex: true,
	},
];
