import React from 'react';
import { matchPath } from 'react-router-dom';
import loadable from '@loadable/component';
import App from './pages/app';
import Nav from './pages/nav';
import { activate } from './activator';
import { categoryLandingPages } from './data/landingPages';
import { LoadingIndicator } from './components/loading-indicator';

const loadableOptions = { fallback: <LoadingIndicator /> };

const HomePage = loadable(() => import('./pages/home'), loadableOptions);
const ItemDetailsPage = loadable(() => import('./pages/item-details'), loadableOptions);
const MediaPage = loadable(() => import('./pages/media'), loadableOptions);
const CategoryPage = loadable(() => import('./pages/category'), loadableOptions);
const CategoryLandingPage = loadable(() => import('./pages/category-landing'), loadableOptions);
const GroupPage = loadable(() => import('./pages/group'), loadableOptions);
const SearchPage = loadable(() => import('./pages/search'), loadableOptions);
const ContentManagementPage = loadable(() => import('./pages/content-management'), loadableOptions);
const ForgotPasswordPage = loadable(() => import('./pages/forgot-password'), loadableOptions);
const RegisterPage = loadable(() => import('./pages/register'), loadableOptions);
const SignInPage = loadable(() => import('./pages/sign-in'), loadableOptions);
const WatchAnywherePage = loadable(() => import('./pages/watch-anywhere'), loadableOptions);
const UnsupportedBrowserPage = loadable(() => import('./pages/unsupported-browser'), loadableOptions);

const landingRoutes = categoryLandingPages.map(landingPage => {
	const route = {
		path: `/${landingPage.token}`,
		component: CategoryLandingPage,
	};
	return route;
});
const notFoundRoute = {
	component: ContentManagementPage,
};

interface IRoute {
	path?: string;
	component?: React.ComponentType<any>;
	routes?: IRoute[];
	exact?: boolean;
	redirect?: boolean;
	to?:
		| {
				base: string;
				params: readonly string[];
		  }
		| string;
}

export const navRoute: IRoute = {
	path: '/',
	component: Nav,
	routes: [
		{
			path: '/',
			exact: true,
			component: HomePage,
		},
		{
			path: '/items/resource/:resourceId/:mediaId',
			component: ItemDetailsPage,
		},
		{
			path: '/items/:itemId',
			component: ItemDetailsPage,
		},
		{
			path: '/category/:categoryId',
			component: CategoryPage,
		},
		{
			path: '/categories/:categoryToken',
			component: CategoryPage,
		},
		{
			path: '/groups/:groupToken',
			component: GroupPage,
		},
		{
			path: '/watch-anywhere',
			component: WatchAnywherePage,
		},
		{
			path: '/signin',
			component: SignInPage,
		},
		{
			path: '/register',
			component: RegisterPage,
		},
		{
			path: '/forgotpassword',
			component: ForgotPasswordPage,
		},
		{
			path: '/search',
			component: SearchPage,
		},
		{
			path: '/unsupported-browser',
			component: UnsupportedBrowserPage,
		},
		{
			redirect: true,
			path: '/item/resource/:resourceId/:mediaId',
			to: { base: '/items/resource', params: ['resourceId', 'mediaId'] },
		},
		{
			redirect: true,
			path: '/item/:itemId',
			to: { base: '/items', params: ['itemId'] },
		},
		{
			redirect: true,
			path: '/bsm',
			to:
				'/get-free-trial?utm_source=biblestudymagazine&utm_medium=print&utm_content=freetrial&utm_campaign=promo-bsmso2018',
		},
		{
			redirect: true,
			path: '/biblesounds',
			to: '/items/307499',
		},
		{
			redirect: true,
			path: '/bibleagent7',
			to: '/items/380974',
		},
		{
			redirect: true,
			path: '/questionsaloud',
			to: '/items/369488',
		},
		{
			redirect: true,
			path: '/questionsallowed',
			to: '/items/369488',
		},
		{
			redirect: true,
			path: '/archaeologyandjesus',
			to: '/items/342835',
		},
		{
			redirect: true,
			path: '/archeologyandjesus',
			to: '/items/342835',
		},
		{
			redirect: true,
			path: '/knownbygod',
			to: '/items/307504',
		},
		{
			redirect: true,
			path: '/jipacker',
			to: '/items/307504',
		},
		{
			redirect: true,
			path: '/spurgeon',
			to: '/items/369496',
		},
		{
			redirect: true,
			path: '/aliens',
			to: '/items/479903',
		},
		{
			redirect: true,
			path: '/trial',
			to:
				'/get-free-trial?utm_source=simulcast&utm_medium=partner&utm_content=tvcommercial&utm_campaign=promo-pilgrimsprogress',
		},
		{
			redirect: true,
			path: '/jashow',
			to: '/?utm_source=johnankerberg&utm_medium=Partner&utm_content=freetrial&utm_campaign=jasfreetrial',
		},
		{
			redirect: true,
			path: '/psalm23',
			to: '/items/493456',
		},
		{
			redirect: true,
			path: '/mtm',
			to: '/get-free-trial?utm_source=ministryteam&utm_medium=print&utm_content=freetrial&utm_campaign=promo-mtjan2019',
		},
		{
			redirect: true,
			path: '/compare',
			to: '/church',
		},
		{
			redirect: true,
			path: '/kids',
			to: '/christian-kids-shows-movies',
		},
		...landingRoutes,
		notFoundRoute,
	],
};

export const appRoute: IRoute = {
	path: '/',
	component: App,
	routes: [
		{
			path: '/media/assets/:assetId',
			component: MediaPage,
		},
		{
			path: '/media/resource/:resourceId/:mediaId',
			component: MediaPage,
		},
		{
			path: '/media/:itemId',
			component: MediaPage,
		},
		navRoute,
	],
};

const routeList = [appRoute];

export function preLoad(pathname: string, store, location: Partial<URL>, staticContext, hasActivated = false) {
	const components: React.ComponentType<any>[] = [];
	const params = {};

	findRouteMatch(routeList, pathname, (route, match) => {
		if (route.component) {
			components.push(route.component);
			Object.assign(params, match.params);
		}
	});

	return activate({ components }, [store, { params, location, staticContext }, hasActivated]);
}

export function findRouteMatch<TResult>(
	routes: readonly IRoute[],
	pathname: string,
	action: (route: IRoute, match) => TResult | undefined
): TResult | undefined {
	let route;
	let match;

	for (route of routes) {
		match = matchPath(pathname, route);
		if (match) {
			break;
		}
	}

	if (route && match) {
		const result = action(route, match);
		if (typeof result !== 'undefined') {
			return result;
		}
	}

	if (route && route.routes) {
		const result = findRouteMatch(route.routes, pathname, action);
		if (typeof result !== 'undefined') {
			return result;
		}
	}

	return undefined;
}
