// eslint-disable-next-line
__webpack_public_path__ = window.__webpack_public_path__ || '/dist/';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ga from 'react-ga';
import { loadableReady } from '@loadable/component';
import { appRoute } from '../shared/routes';
import { configureStore } from '../shared/configure-store';
import { getViewRoot } from '../shared/root';
import * as clients from './http';

const store = configureStore(window.__INITIAL_STATE__ || {}, clients);
const view = (
	<BrowserRouter>
		<Switch>
			<Route {...appRoute} />
		</Switch>
	</BrowserRouter>
);

ga.initialize('UA-31831066-12', { debug: DEBUG });
ga.set({ dimension1: 'Web' });
ga.plugin.require('ecommerce');
loadableReady(() => {
	ReactDOM.hydrate(getViewRoot(view, store), document.getElementById('app'));
});
