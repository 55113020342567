import { getTopItemsInCategoriesTypes } from '../actions/action-types';
import { IItem } from './item-reducers';
import { IReduxReducerStateActions } from '.';

export interface ITopItemsState extends IReduxReducerStateActions {
	categoryIds?: readonly string[];
	items?: IItem[] | null;
}

export function topItems(state: ITopItemsState = {}, action): ITopItemsState {
	const { type, result, error } = action;
	switch (type) {
		case getTopItemsInCategoriesTypes.processing:
			return { ...state, isFetching: true, items: null, error: null };

		case getTopItemsInCategoriesTypes.success:
			return {
				...state,
				isFetching: false,
				categoryIds: result.categoryIds,
				items: result.items,
				error: null,
			};

		case getTopItemsInCategoriesTypes.error:
			return { ...state, isFetching: false, error };

		default:
			return state;
	}
}
