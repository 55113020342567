export const featuredItems = [
	{
		id: '705117',
		heroImageUrl: 'https://files.logoscdn.com/v1/files/43147744/content.png?signature=JE1_l-rUmr3ikfDURInKcStrJIk',
		promotionPath: '/reel-redemption',
	},
	{
		id: '701635',
		heroImageUrl: 'https://files.logoscdn.com/v1/files/40939106/content.jpg?signature=4aTytMpzlbp4Yq78KXOs8dXSKEQ',
	},
	{
		id: '575439',
		heroImageUrl: 'https://files.logoscdn.com/v1/files/40939206/content.jpg?signature=VjSSEJunGoWb_v0a8unN11P3FB4',
		promotionPath: '/fragments-of-truth',
	},
	{
		id: '621210',
		heroImageUrl: 'https://files.logoscdn.com/v1/files/40939199/content.jpg?signature=sq-f0d0GiIy8pQdGp6uM48fo40g',
		promotionPath: '/authorized',
	},
	{
		id: '479903',
		heroImageUrl: 'https://files.logoscdn.com/v1/files/40938801/content.jpg?signature=InsEARDaTfyN6XOQ4k5cgOAhAUo',
		promotionPath: '/aliens-and-demons',
	},
	{
		id: '342835',
		heroImageUrl: 'https://files.logoscdn.com/v1/files/40939169/content.jpg?signature=5ac5OzxO1Ym97MiiSgNk3EqnUfA',
	},
	{
		id: '621089',
		heroImageUrl: 'https://files.logoscdn.com/v1/files/40939218/content.jpg?signature=CrGQ7J-L2-_WgY7aDHhJCD0WNj8',
		promotionPath: '/bible-agent-7',
	},
	{
		id: '655033',
		heroImageUrl: 'https://files.logoscdn.com/v1/files/40939229/content.jpg?signature=PR_amcGw-0UyEP8Weh3_nrNrKhE',
	},
	{
		id: '586207',
		heroImageUrl: 'https://files.logoscdn.com/v1/files/40939267/content.jpg?signature=-D6Uo2jpjW-QifxH3gAwgUEiuUk',
	},
];
