import { getAdminGroupsTypes, IActionDispatch } from './action-types';
import { FaithlifeTVClients } from '../clients/clients';
import { IRootState } from '../reducers';

function getAdminGroups() {
	return {
		types: getAdminGroupsTypes,
		promise: ({ accountsClient }: FaithlifeTVClients) => accountsClient.getAdminGroups(),
	};
}

export function getAdminGroupsIfNecessary() {
	return (dispatch: IActionDispatch, getState: () => IRootState) => {
		const { admin, auth } = getState();
		if (!admin.isProcessing && !admin.isLoaded && auth.user?.id && auth.user.id !== '-1') {
			return dispatch(getAdminGroups());
		}
		return Promise.resolve(admin);
	};
}
