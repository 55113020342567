/* global ga */
/* exported ga */
import { clearPurchaseItemType, purchaseItemTypes } from './action-types';
import logger from '../utils/logger';
import { ITvClient } from '../clients/tv';
import { FaithlifeTVClients } from '../clients/clients';

let ga;
if (typeof window !== 'undefined') {
	ga = window.ga;
}

export function clearPurchaseItem() {
	return {
		type: clearPurchaseItemType,
	};
}

export function purchaseItem({ storefront, skuId, productToken }) {
	return {
		types: purchaseItemTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => doPurchaseItem(tvClient, storefront, skuId, productToken),
	};
}

async function doPurchaseItem(tvClient: ITvClient, storefront: string, skuId: string, productToken: string) {
	const response = await tvClient.quickbuy(storefront, skuId, productToken);
	if (typeof window !== 'undefined' && typeof ga !== 'undefined') {
		if (response && response.order) {
			const revenue =
				response.order.totalAmount && response.order.totalAmount.usDollar
					? response.order.totalAmount.usDollar.amount
					: '';
			ga('ecommerce:addTransaction', {
				id: response.order.orderTxnId,
				affiliation: 'faithlifetv.com',
				revenue,
				tax:
					response.order.taxAmount && response.order.taxAmount.usDollar ? response.order.taxAmount.usDollar.amount : '',
			});
			if (response.order.lines && response.order.lines.length) {
				for (const currentLine of response.order.lines) {
					const itemAmount =
						currentLine.unitPrice && currentLine.unitPrice.usDollar ? currentLine.unitPrice.usDollar.amount : '';
					const itemName = currentLine.sku ? currentLine.sku.name : '';
					const itemSkuId = currentLine.sku ? currentLine.sku.skuId : '';
					const itemQuantity = currentLine.quantity;
					ga('ecommerce:addItem', {
						id: response.order.orderTxnId,
						name: itemName,
						sku: itemSkuId,
						quantity: itemQuantity,
						price: itemAmount,
					});
				}
			}
			ga('ecommerce:send');
			ga('ecommerce:clear');
		} else {
			logger.error(response, "Error getting a response or response's order when attempting to buy a single item. ");
		}
	}
	return { skuId, purchaseSuccess: response.purchaseSuccess };
}
