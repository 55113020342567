import {
	clearCategoriesType,
	getCategoriesTypes,
	getKidsCategoriesTypes,
	getItemsInCategoryTypes,
	getPlaylistsTypes,
	getGetItemsInPlaylistTypes,
	getDefaultCategoriesTypes,
} from '../actions/action-types';
import { ICategory } from './category-reducers';
import { IPlaylist } from '../pages/home/playlist';
import { IReduxReducerStateActions } from '.';

interface ICategoriesTypeState extends IReduxReducerStateActions {
	categories?: ICategory[] | null;
	defaultCategories?: ICategory[] | null;
}

export interface ICategoriesState {
	categories: ICategoriesTypeState;
	kidsCategories: ICategoriesTypeState;
}

export interface IPlaylistsState extends IReduxReducerStateActions {
	playlists?: IPlaylist[] | null;
}

export function categories(state: ICategoriesState = { categories: {}, kidsCategories: {} }, action): ICategoriesState {
	const { type, result, error, isKids } = action;

	switch (type) {
		case clearCategoriesType:
			return {
				...state,
				categories: { categories: null },
				kidsCategories: { categories: null },
			};

		case getCategoriesTypes.processing:
			return {
				...state,
				categories: {
					...state.categories,
					isFetching: true,
					error: null,
					categories: state.categories.categories || null,
				},
			};

		case getCategoriesTypes.success:
			return { ...state, categories: { ...state.categories, isFetching: false, categories: result, error: null } };

		case getCategoriesTypes.error:
			return { ...state, categories: { ...state.categories, isFetching: false, error } };

		case getKidsCategoriesTypes.processing:
			return {
				...state,
				kidsCategories: {
					isFetching: true,
					categories: state.kidsCategories.categories || null,
					error: null,
				},
			};

		case getKidsCategoriesTypes.success:
			return { ...state, kidsCategories: { isFetching: false, categories: result, error: null } };

		case getKidsCategoriesTypes.error:
			return { ...state, kidsCategories: { isFetching: false, error } };

		case getDefaultCategoriesTypes.success:
			return { ...state, categories: { ...state.categories, defaultCategories: result } };

		case getItemsInCategoryTypes.processing:
			return {
				...state,
				kidsCategories: isKids
					? { ...state.kidsCategories, categories: state.kidsCategories.categories || null, error: null }
					: state.kidsCategories,
				categories: !isKids
					? { ...state.categories, categories: state.categories.categories || null, error: null }
					: state.categories,
			};

		case getItemsInCategoryTypes.success: {
			const { categoryId, items, fromPage, nextPage } = result;
			const categories = isKids ? state.kidsCategories.categories : state.categories.categories;

			if (categories) {
				return {
					...state,
					kidsCategories:
						isKids && state.kidsCategories.categories
							? {
									...state.kidsCategories,
									categories: [
										...state.kidsCategories.categories.map(category => {
											if (category.id !== categoryId) {
												return category;
											}

											return {
												...category,
												items: fromPage ? [...category.items, ...items] : items,
												nextPage: nextPage,
											};
										}),
									],
							  }
							: state.kidsCategories,
					categories:
						!isKids && state.categories.categories
							? {
									...state.categories,
									categories: [
										...state.categories.categories.map(category => {
											if (category.id !== categoryId) {
												return category;
											}

											return {
												...category,
												items: fromPage ? [...category.items, ...items] : items,
												nextPage: nextPage,
											};
										}),
									],
							  }
							: state.categories,
				};
			}

			return { ...state };
		}

		case getItemsInCategoryTypes.error:
			return {
				...state,
				kidsCategories: isKids ? { ...state.kidsCategories, error } : state.kidsCategories,
				categories: !isKids ? { ...state.categories, error } : state.categories,
			};

		default:
			return state;
	}
}

export function playlists(state: IPlaylistsState = {}, action): IPlaylistsState {
	const { type, result, error } = action;

	switch (type) {
		case getPlaylistsTypes.processing:
			return { ...state, isFetching: true, playlists: null, error: null };

		case getPlaylistsTypes.success:
			return { ...state, isFetching: false, playlists: result, error: null };

		case getPlaylistsTypes.error:
			return { ...state, isFetching: false, error };

		case getGetItemsInPlaylistTypes.processing:
			return {
				...state,
				isFetching: true,
				playlists: state.playlists || null,
				error: null,
			};

		case getGetItemsInPlaylistTypes.success:
			// TODO: Fix this the next time the file is edited.
			// eslint-disable-next-line no-case-declarations
			const playlists = state.playlists;
			if (playlists) {
				const { playlistId, items, nextPage } = result;
				const updatedPlaylist = playlists.find(x => x.id === playlistId);
				if (updatedPlaylist) {
					// if this was from a "get next page" request, append, otherwise, replace
					updatedPlaylist.items = nextPage ? updatedPlaylist.items.concat(items) : items;
				}
			}
			return { ...state, isFetching: false, playlists, error: null };

		case getGetItemsInPlaylistTypes.error:
			return { ...state, isFetching: false, error };

		default:
			return state;
	}
}
