import React from 'react';
import { CornerRibbon } from '../components/corner-ribbon';
import { PlusRibbon } from '../components/plus-ribbon';

export function getSubscriptionPeriodLabel(periodKind) {
	return periodKind === 'Day' ? 'day' : periodKind === 'Month' ? 'month' : periodKind === 'Year' ? 'year' : undefined;
}

export function getSubscriptionPeriodShortLabel(periodKind) {
	return periodKind === 'Day' ? 'day' : periodKind === 'Month' ? 'mo' : periodKind === 'Year' ? 'yr' : undefined;
}

export function getPurchaseButtonLabel(productInfo, subscriptionPrefixText = 'Subscribe ') {
	if (!productInfo) {
		return undefined;
	}

	let buttonText;
	if (productInfo.subscriptionPeriodKind) {
		if (productInfo.price) {
			buttonText = `${productInfo.price}`;
			const periodLabel = getSubscriptionPeriodLabel(productInfo.subscriptionPeriodKind);
			if (periodLabel) {
				buttonText = `${subscriptionPrefixText || ''}${buttonText}/${periodLabel}`;
			}
		} else {
			buttonText = 'Start free trial';
		}
	} else if (productInfo.price) {
		buttonText = `Purchase ${productInfo.price}`;
	} else {
		buttonText = 'Purchase to watch';
	}
	return buttonText;
}

export function renderRibbonIfNecessary(productInfo, size?: 'item' | 'compact') {
	if (!productInfo) {
		return false;
	}
	if (!size) {
		size = 'item';
	}
	return productInfo.showPromotionRibbon ? (
		<PlusRibbon type={size} />
	) : productInfo.priceDisplayAmount && !productInfo.subscriptionPeriodKind ? (
		<CornerRibbon text={productInfo.priceDisplayAmount} type={size} />
	) : productInfo.showOwnedCornerRibbon ? (
		<CornerRibbon text="Owned" type={size} />
	) : productInfo.showFreeCornerRibbon ? (
		<CornerRibbon text="FREE" type={size} />
	) : (
		false
	);
}
