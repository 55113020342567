import {
	getGroupSettingsRestrictedTypes,
	addGroupSettingsRestrictedItemTypes,
	removeGroupSettingsRestrictedItemTypes,
} from '../actions/action-types';
import { IReduxReducerStateActions } from '.';
import { IAdminGroup } from './admin-reducers';

export interface IGroupSettingsRestrictedState extends IReduxReducerStateActions {
	restricted?: { itemId: string; restrictedGroups: IAdminGroup[] };
}

export function groupSettingsRestricted(
	state: IGroupSettingsRestrictedState = {},
	action
): IGroupSettingsRestrictedState {
	const { type, result, error } = action;

	switch (type) {
		case getGroupSettingsRestrictedTypes.processing:
		case addGroupSettingsRestrictedItemTypes.processing:
		case removeGroupSettingsRestrictedItemTypes.processing:
			return { ...state, isProcessing: true, error: null };

		case getGroupSettingsRestrictedTypes.success:
			return {
				...state,
				error: null,
				isProcessing: false,
				restricted: result,
			};

		case addGroupSettingsRestrictedItemTypes.success:
		case removeGroupSettingsRestrictedItemTypes.success:
			return { ...state, isProcessing: false, error: null };

		case getGroupSettingsRestrictedTypes.error:
		case addGroupSettingsRestrictedItemTypes.error:
		case removeGroupSettingsRestrictedItemTypes.error:
			return { ...state, isProcessing: false, error: error.message };

		default:
			return state;
	}
}
