import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from '../../connect';
import { navRoute } from '../../routes';
import logger from '../../utils/logger';
import { Footer } from '../../components/footer';
import { NavBar } from '../../components/nav-bar';
import { LoadingIndicator } from '../../components/loading-indicator';
import { amplitudeIdentify } from '../../helpers/amplitude-helper';
import {
	signOut,
	getCurrentUserIfNecessary,
	getCategories,
	getPlaylists,
	updateCategories,
	clearCategories,
	getWatchlist,
	initializeDefaultCategories,
} from '../../actions';
import { IAuthState } from '../../reducers/auth-reducers';
import { ISubscriptionState } from '../../reducers/subscription-reducers';
import { IKidsModeState } from '../../reducers/kids-mode-reducers';
import { ICategoriesState } from '../../reducers/home-reducers';
import { ISearchState } from '../../reducers/search-reducers';
import { IReduxProps } from '../../actions/action-types';
import { IFeatureFlagsState } from '../../reducers/feature-flags-reducers';

function mapStateToProps({ auth, subscription, search, categories, kidsMode, featureFlags }) {
	return {
		auth,
		subscription,
		search,
		categories,
		kidsMode,
		featureFlags,
	};
}

interface INavProps extends IReduxProps {
	auth: IAuthState;
	location: URL;
	subscription: ISubscriptionState;
	search: ISearchState;
	categories: ICategoriesState;
	kidsMode: IKidsModeState;
	featureFlags: IFeatureFlagsState;
}

interface INavState {
	isChangingKidsMode: boolean;
}

@connect(mapStateToProps)
export default class Nav extends React.Component<INavProps, INavState> {
	static activate({ dispatch }) {
		return Promise.all([
			dispatch(getCurrentUserIfNecessary()).catch(logger.error),
			dispatch(initializeDefaultCategories()).catch(logger.error),
		]);
	}

	state: INavState = {
		isChangingKidsMode: false,
	};

	componentDidMount() {
		const {
			dispatch,
			kidsMode: { isKidsMode },
		} = this.props;
		dispatch(updateCategories(isKidsMode)).catch(logger.error);
		dispatch(getPlaylists({ reload: true })).catch(logger.error);
		dispatch(getWatchlist()).catch(logger.error);
	}

	componentWillReceiveProps(nextProps: INavProps) {
		const {
			auth,
			dispatch,
			kidsMode: { isKidsMode },
		} = this.props;
		const {
			auth: nextAuth,
			categories: nextCategories,
			kidsMode: { isKidsMode: nextIsKidsMode },
		} = nextProps;
		const nextUserId = nextAuth.user && nextAuth.user.id;
		if (!nextAuth.isProcessing && nextUserId !== '-1' && nextUserId !== auth.user.id) {
			dispatch(clearCategories());
			dispatch(getCategories()).catch(logger.error);
		}
		if (nextIsKidsMode !== isKidsMode) {
			if (nextIsKidsMode) {
				dispatch(getCategories(true, nextIsKidsMode)).catch(logger.error);
				this.setState({ isChangingKidsMode: true });
				amplitudeIdentify('Kids Mode', nextIsKidsMode);
			} else if (isKidsMode) {
				dispatch(getCategories(true)).catch(logger.error);
				this.setState({ isChangingKidsMode: true });
				amplitudeIdentify('Kids Mode');
			}
		} else if (
			(!nextIsKidsMode && nextCategories.categories.defaultCategories) ||
			(nextIsKidsMode && !nextCategories.kidsCategories.isFetching)
		) {
			this.setState({ isChangingKidsMode: false });
		}
	}

	handleSignOut() {
		const { auth, dispatch } = this.props;
		if (!auth.isProcessing) {
			dispatch(signOut());
		}
	}

	render() {
		const { categories, subscription, kidsMode, auth, dispatch, location, search } = this.props;

		return (
			<div style={{ position: 'relative', minHeight: '100%' }} className={kidsMode.isKidsMode ? 'kids' : ''}>
				<NavBar
					user={auth.user}
					dispatch={dispatch}
					location={location}
					subscription={subscription}
					search={search}
					categories={categories}
					kidsMode={kidsMode}
				/>
				{this.state.isChangingKidsMode ? (
					<LoadingIndicator />
				) : (
					<Switch>
						{navRoute.routes?.map((route, index) => {
							const { redirect, to, path } = route;
							return redirect ? (
								<Route
									key={index}
									path={path}
									render={({ match }) => (
										<Redirect
											to={
												to && typeof to !== 'string' && to.params
													? `${to.base}/${to.params.map(p => match.params[p]).join('/')}`
													: to
											}
										/>
									)}
								/>
							) : (
								<Route key={index} {...route} />
							);
						})}
					</Switch>
				)}

				<Footer kidsMode={kidsMode} />
			</div>
		);
	}
}
