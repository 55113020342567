import { formatUrl } from '../utils/url';
import { IProgress } from '../reducers/item-reducers';
import { IFetcher } from './fetcher';

export type ITvClient = ReturnType<typeof createTvClient>;

export function createTvClient({ fetchJson }: { fetchJson: IFetcher }) {
	const homePageFields = ['id', 'title', 'covers', 'licenseKind'];

	return {
		getSubscriptions() {
			return fetchJson('subscriptions');
		},

		getMembership() {
			return fetchJson('membership');
		},

		getWatchlist() {
			return fetchJson('watchlist');
		},

		getPlaylist({
			playlistId,
			showUnlicensed = false,
			limit = 20,
		}: {
			playlistId: string;
			showUnlicensed: boolean;
			limit?: number;
		}) {
			const url = formatUrl(`playlists/${playlistId}`, {
				limit,
				fields: homePageFields.join(','),
				showUnlicensed,
			});
			return fetchJson(url);
		},

		getCategories(supportedItemKinds: readonly string[] = [], showUnlicensed = true, parentId: string | null = null) {
			const url = formatUrl('categories', {
				supportedItemKinds: supportedItemKinds.join(','),
				showUnlicensed,
				parentId,
				showSubcategories: true,
			});
			return fetchJson(url);
		},

		getCategoriesByOwner(ownerId: string) {
			const url = formatUrl('categories', {
				ownerId,
			});
			return fetchJson(url);
		},

		getItemsInCategory(
			categoryId: string,
			pageToken: string | null = null,
			showUnlicensed = true,
			showOpenGroups = false,
			limit = 20,
			parentId: string | null = null
		) {
			if (!categoryId) {
				throw new Error('categoryId required');
			}
			const url = formatUrl('items', {
				categoryId,
				showUnlicensed,
				showOpenGroups,
				limit,
				fields: homePageFields.join(','),
				page: pageToken,
				parentId,
			});
			return fetchJson(url).catch(() => []);
		},

		getItemsByCategory(
			categoryIds: readonly string[],
			showUnlicensed = true,
			limit = 20,
			parentId: string | null = null
		) {
			if (!categoryIds) {
				throw new Error('categoryIds is required');
			}
			return fetchJson('items/bycategory', {
				method: 'POST',
				body: {
					categoryIds,
					fields: homePageFields,
					showUnlicensed,
					limit,
					parentId,
				},
			});
		},

		getItemsBySearchQuery(
			query: string,
			pageToken: string | null = null,
			showUnlicensed = true,
			limit = 100,
			categoryId: string | null = null
		) {
			if (!query) {
				throw new Error('query is required');
			}
			const url = formatUrl('items/search', {
				q: query,
				limit,
				showUnlicensed,
				page: pageToken,
				categoryId,
			});
			return fetchJson(url);
		},

		getItemForAsset(assetId: string, fields: readonly string[]) {
			if (!assetId) {
				throw new Error('assetId required');
			}
			const url = formatUrl('items', {
				assetId,
				showUnlicensed: true,
				showOpenGroups: true,
				fields: fields && fields.join(','),
			});
			return fetchJson(url);
		},

		getItemForResource(resourceId: string, mediaId: string, fields: readonly string[]) {
			if (!resourceId || !mediaId) {
				throw new Error('resourceId and mediaId required');
			}
			const url = formatUrl('items', {
				resourceId,
				mediaId,
				showUnlicensed: true,
				showOpenGroups: true,
				fields: fields && fields.join(','),
			});
			return fetchJson(url);
		},

		getItem(itemId?: string, fields?: readonly string[]) {
			if (!itemId) {
				throw new Error('itemId required');
			}
			const url = formatUrl(`items/${encodeURIComponent(itemId)}`, {
				showUnlicensed: true,
				showOpenGroups: true,
				fields: fields && fields.join(','),
			});
			return fetchJson(url);
		},

		getItems(itemIds: readonly string[], fields: readonly string[]) {
			if (!itemIds) {
				throw new Error('itemIds required');
			}
			return fetchJson('items/selectmany', {
				method: 'POST',
				body: { items: itemIds, fields, showUnlicensed: true },
			});
		},

		getNextItems(
			itemId,
			{
				playlistId,
				fields = homePageFields,
				showUnlicensed = false,
				limit = 20,
			}: { playlistId: string; fields?: readonly string[]; showUnlicensed: boolean; limit?: number }
		) {
			if (!itemId) {
				throw new Error('itemId required');
			}
			const url = formatUrl('nextItems', {
				itemId,
				fields: fields.join(','),
				limit,
				showUnlicensed,
				playlistId,
			});
			return fetchJson(url);
		},

		setItemProgress(itemId: string, progress: IProgress, categoryParentId: string | null = null) {
			if (!itemId) {
				throw new Error('itemId required');
			}
			if (!progress) {
				throw new Error('progress required');
			}
			return fetchJson(`items/${encodeURIComponent(itemId)}/progress`, {
				method: 'POST',
				body: { progress, categoryParentId },
			});
		},

		rateItem(itemId?: string, rating?: number, shareToFaithlife?: boolean) {
			if (!itemId) {
				throw new Error('itemId required');
			}
			if (!rating) {
				throw new Error('rating required');
			}
			return fetchJson(`items/${encodeURIComponent(itemId)}/rating`, {
				method: 'POST',
				body: { value: rating, share: shareToFaithlife },
			});
		},

		quickbuy(storefront, skuId: string, productToken: string) {
			if (!storefront) {
				throw new Error('storefront required');
			}
			if (!skuId) {
				throw new Error('skuId required');
			}
			if (!productToken) {
				throw new Error('productToken required');
			}
			return fetchJson('quickbuy', {
				method: 'POST',
				body: { storefront, skuId, productToken },
			}).then(response => {
				if (!response.purchaseSuccess) {
					throw new Error(response.error || 'Failed to purchase product');
				}
				return response;
			});
		},

		getRestrictedItems(groupIds: string[], itemId: string) {
			if (!groupIds) {
				throw new Error('groupIds required');
			}
			if (!itemId) {
				throw new Error('itemId required');
			}
			return fetchJson(`settings/groups/restricteditems/${itemId}`, {
				method: 'POST',
				body: { groupIds: groupIds },
			});
		},

		addRestrictedItem(groupId: string, itemId: string) {
			if (!groupId) {
				throw new Error('groupId required');
			}
			if (!itemId) {
				throw new Error('itemId required');
			}
			return fetchJson(`settings/groups/${groupId}/restricteditems/${itemId}`, {
				method: 'PUT',
			});
		},

		removeRestrictedItem(groupId: string, itemId: string) {
			if (!groupId) {
				throw new Error('groupId required');
			}
			if (!itemId) {
				throw new Error('itemId required');
			}
			return fetchJson(`settings/groups/${groupId}/restricteditems/${itemId}`, {
				method: 'DELETE',
			});
		},

		getGroupCategories(groupId: string) {
			if (!groupId) {
				throw new Error('groupId required');
			}
			return fetchJson(`settings/groups/${groupId}/categories`);
		},

		validateGroupItem(
			groupId: string,
			itemId: string,
			disallowGroupLicenses: boolean = false,
			onlyAllowParentItems: boolean = false
		) {
			if (!groupId) {
				throw new Error('groupId required');
			}
			if (!itemId) {
				throw new Error('itemId required');
			}
			return fetchJson(
				`settings/groups/${groupId}/items/validate?disallowGroupLicenses=${disallowGroupLicenses}&onlyAllowParentItems=${onlyAllowParentItems}`,
				{
					method: 'POST',
					body: { itemIds: [itemId] },
				}
			);
		},

		createGroupCategory(groupId: string, { title, token }: { title: string; token: string }) {
			if (!groupId) {
				throw new Error('groupId required');
			}
			if (!title) {
				throw new Error('title required');
			}
			if (!token) {
				throw new Error('token required');
			}
			return fetchJson(`settings/groups/${groupId}/categories`, {
				method: 'POST',
				body: { title, token },
			});
		},

		addItemToWatchlist(itemId: string) {
			if (!itemId) {
				throw new Error('itemId required');
			}

			return fetchJson(`watchlist/add?itemIds=${itemId}`, {
				method: 'POST',
			});
		},

		removeItemFromWatchlist(itemId: string) {
			if (!itemId) {
				throw new Error('itemId required');
			}

			return fetchJson(`watchlist/remove?itemIds=${itemId}`, {
				method: 'POST',
			});
		},

		deleteGroupCategory(groupId: string, categoryId: string) {
			if (!groupId) {
				throw new Error('groupId required');
			}
			if (!categoryId) {
				throw new Error('categoryId required');
			}
			return fetchJson(`settings/groups/${groupId}/categories/${categoryId}`, {
				method: 'DELETE',
			});
		},

		updateGroupCategoryItemIds(groupId: string, categoryId: string, itemIds: readonly string[]) {
			if (!groupId) {
				throw new Error('groupId required');
			}
			if (!categoryId) {
				throw new Error('categoryId required');
			}
			if (!itemIds || !itemIds.length) {
				throw new Error('itemIds required');
			}
			return fetchJson(`settings/groups/${groupId}/categories/${categoryId}/items`, {
				method: 'PUT',
				body: { itemIds },
			});
		},
	};
}
