export default {
	info(...args) {
		console.info(...args);
	},
	warn(...args) {
		console.warn(...args);
	},
	error(err, ...args) {
		console.error((err && err.stack) || err, ...args);
		if (typeof window !== 'undefined' && typeof window.rg4js === 'function' && err instanceof Error) {
			window.rg4js('send', err);
		}
	},
};
