import { createFetcher } from './helpers';
import { createAccountsClient } from '../../shared/clients/accounts';
import { createAuthClient } from '../../shared/clients/auth';
import { createContentManagementClient } from '../../shared/clients/content-management';
import { createLocationServicesClient } from '../../shared/clients/location-services';
import { createTvClient } from '../../shared/clients/tv';
import { createProductsClient } from '../../shared/clients/products';

export const accountsClient = createAccountsClient({
	fetchJson: createFetcher({ baseUrl: '/api/accounts/' }),
});

export const authClient = createAuthClient({
	fetchJson: createFetcher({ baseUrl: '/auth/' }),
});

export const tvClient = createTvClient({
	fetchJson: createFetcher({ baseUrl: '/api/tv/' }),
});

export const productsClient = createProductsClient({
	fetchJson: createFetcher({ baseUrl: '/api/products/' }),
});

export const contentManagementClient = createContentManagementClient({
	fetchJson: createFetcher({ baseUrl: '/api/contentmanagement/' }),
});

export const locationServicesClient = createLocationServicesClient({
	fetchJson: createFetcher({ baseUrl: '/api/location-services/' }),
});
