import { getContentManagementTypes, IActionDispatch } from './action-types';
import { FaithlifeTVClients } from '../clients/clients';
import { IRootState } from '../reducers';

export const getContentManagementPage = (page: string) => {
	return {
		types: getContentManagementTypes,
		promise: ({ contentManagementClient }: FaithlifeTVClients) => contentManagementClient.getPage(page),
	};
};

export const getContentManagementPageIfNecessary = (page: string) => (
	dispatch: IActionDispatch,
	getState: () => IRootState
) => {
	const { contentManagement } = getState();
	if (contentManagement && contentManagement.isError) {
		return Promise.reject(new Error(`Failed to get ${page}`));
	} else if (
		contentManagement &&
		contentManagement.page &&
		contentManagement.page.path &&
		contentManagement.page.path.toLowerCase() === page.toLowerCase()
	) {
		return Promise.resolve();
	}

	return dispatch(getContentManagementPage(page.substring(1)));
};
