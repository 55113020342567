import { getContentManagementTypes } from '../actions/action-types';
import { IReduxReducerStateActions } from '.';

export interface IPage {
	sections: any;
	metadata: any;
	title: string;
	shouldIndex: boolean;
	path: string;
	shouldRedirect: boolean;
	redirectLocation?: string;
}
export interface IContentManagementState extends IReduxReducerStateActions {
	page?: IPage | null;
}

export function contentManagement(state: IContentManagementState = {}, action): IContentManagementState {
	const { type, result } = action;

	switch (type) {
		case getContentManagementTypes.processing:
			return { ...state, isFetching: true, isError: false, page: null };

		case getContentManagementTypes.success:
			return { ...state, isFetching: false, isError: false, page: result };

		case getContentManagementTypes.error:
			return { ...state, isFetching: false, isError: true, page: null };

		default:
			return state;
	}
}
