import { IFetcher } from './fetcher';

export type IContentManagementClient = ReturnType<typeof createContentManagementClient>;

export function createContentManagementClient({ fetchJson }: { fetchJson: IFetcher }) {
	return {
		getPage(page: string) {
			return fetchJson(page);
		},
	};
}
