import React from 'react';
import { Link } from 'react-router-dom';
import './index.less';
import { IKidsModeState } from '../../reducers/kids-mode-reducers';

interface IFooterProps {
	kidsMode: IKidsModeState;
}

export class Footer extends React.Component<IFooterProps> {
	render() {
		return (
			<div className="footer">
				<footer className={this.props.kidsMode.isKidsMode ? 'kids' : ''}>
					<div className="copyright">
						<a href="https://faithlife.com/about">Copyright {new Date().getFullYear()} Faithlife</a>
					</div>
					<ul>
						<li>
							<a href="https://faithlife.com/terms">Terms of Use</a>
						</li>
						<li>
							<a href="https://faithlife.com/privacy">Privacy Policy</a>
						</li>
						<li>
							<Link to="/watch-anywhere">Ways to Watch</Link>
						</li>
					</ul>
				</footer>
			</div>
		);
	}
}
