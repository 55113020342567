import React from 'react';
import { CloseButton } from './close-button';
import './index.less';
import { IKidsModeState } from '../../reducers/kids-mode-reducers';

const defaultPlaceholder = 'Search';

interface ISearchBoxProps {
	searchText?: string;
	searchDelay?: number;
	placeholder?: string;
	onChange: (string) => void;
	kidsMode: IKidsModeState;
	onChangeCollapsed: (boolean) => void;
	collapsed?: boolean;
}

interface ISearchBoxState {
	searchText?: string;
}

export class SearchBox extends React.Component<ISearchBoxProps, ISearchBoxState> {
	state: ISearchBoxState = {
		searchText: this.props.searchText || '',
	};

	componentWillReceiveProps = ({ searchText, kidsMode }: ISearchBoxProps) => {
		if (!searchText && kidsMode.isKidsMode && !this.props.kidsMode.isKidsMode) {
			this.props.onChangeCollapsed(true);
		} else if (this.props.kidsMode.isKidsMode && !kidsMode.isKidsMode) {
			this.props.onChangeCollapsed(false);
		}
		this.setState({ searchText });
	};

	handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
		const searchText = event.target.value;
		this.setState({ searchText });
		this.props.onChange(searchText);
	}

	clearText = () => {
		const searchText = '';
		this.setState({ searchText });
		this.props.onChange(searchText);
	};

	showSearchInput = () => {
		this.props.onChangeCollapsed(false);
	};

	hideSearchInput = () => {
		const searchText = this.state.searchText;
		if (!searchText) {
			this.props.onChangeCollapsed(true);
		}
	};

	render() {
		const { searchText } = this.state;
		const placeholder = this.props.placeholder || defaultPlaceholder;

		return (
			<div className="search-box">
				{this.props.collapsed ? (
					<button className="search-button" onClick={this.showSearchInput}>
						<SearchIcon />
					</button>
				) : (
					<div
						className={this.props.kidsMode.isKidsMode ? 'search search-kids' : 'search'}
						onBlur={this.props.kidsMode.isKidsMode ? this.hideSearchInput : () => {}}
					>
						<SearchIcon />
						<input
							autoFocus={this.props.kidsMode.isKidsMode && !this.props.collapsed}
							type="text"
							onChange={e => this.handleInputChange(e)}
							value={searchText}
							size={20}
							placeholder={placeholder}
						/>
						<CloseButton
							className={searchText && searchText.length > 0 ? 'close-button-visible' : 'close-button-hidden'}
							onClick={this.clearText}
						/>
					</div>
				)}
			</div>
		);
	}
}

const SearchIcon = () => (
	<svg width="16" height="22" viewBox="0 0 16 22" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10.226 14.48c-1.07.807-2.4 1.286-3.843 1.286C2.858 15.766 0 12.908 0 9.383S2.858 3 6.383 3s6.383 2.858 6.383 6.383c0 1.443-.48 2.774-1.287 3.843l.14.14 3.753 3.753.627.626L14.746 19l-.627-.627-3.754-3.754-.14-.14zm-3.843-.487c2.546 0 4.61-2.064 4.61-4.61 0-2.546-2.064-4.61-4.61-4.61-2.546 0-4.61 2.064-4.61 4.61 0 2.546 2.064 4.61 4.61 4.61z"
			fill="#FFFFFF"
			fillRule="evenodd"
		/>
	</svg>
);
