import React from 'react';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import { Link } from 'react-router-dom';
import { manageSubscriptionUrl } from '../../data/products';
import { getCategoryLink } from '../../utils/category-utils';
import './index.less';
import { IUser } from '../../reducers/auth-reducers';
import { ISubscriptionState } from '../../reducers/subscription-reducers';
import { IKidsModeState } from '../../reducers/kids-mode-reducers';
import { ICategoriesState } from '../../reducers/home-reducers';

interface IHamburgerMenuProps {
	user: IUser;
	location: URL;
	subscription: ISubscriptionState;
	categories: ICategoriesState;
	kidsMode: IKidsModeState;
	onEnterKids: () => void;
	onExitKids: () => void;
}

export class HamburgerMenu extends React.Component<IHamburgerMenuProps> {
	private dropdown?: Dropdown;
	private signOutForm?: HTMLFormElement | null;

	signOut(event: React.SyntheticEvent) {
		const {
			kidsMode: { isKidsMode },
			onExitKids,
		} = this.props;
		const signOutForm = this.signOutForm;
		event.preventDefault();
		if (signOutForm) {
			signOutForm.submit();
		}
		if (isKidsMode) {
			onExitKids();
		}
	}

	hideSignInLink(currentLocation: string) {
		const hiddenLocations = ['/signin', '/register', '/forgotpassword'];
		return hiddenLocations.includes(currentLocation);
	}

	_handleClick = () => {
		this.dropdown.hide();
	};

	render() {
		const {
			user,
			location: { pathname },
			subscription: { hasSubscription },
			categories: { categories },
			kidsMode: { isKidsMode },
			onExitKids,
			onEnterKids,
		} = this.props;
		const isUserLoggedIn = user && user.id && user.id !== '-1';
		const listItems: JSX.Element[] = [];

		if (isUserLoggedIn) {
			listItems.push(<li key="userName">{user.alias || user.name}</li>);
		}

		if (!isKidsMode && !hasSubscription) {
			listItems.push(
				<li key="getFreeTrial">
					<Link to="/get-free-trial">Learn more</Link>
				</li>
			);
		}

		listItems.push(
			<li key="waysToWatch">
				<Link to="/watch-anywhere">Ways to watch</Link>
			</li>
		);

		if (isUserLoggedIn) {
			if (hasSubscription) {
				if (isKidsMode) {
					listItems.push(
						<li key="exitKidsMode">
							{/* TODO: Fix this the next time the file is edited. */}
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
							<a href="" onClick={onExitKids}>
								Exit Kids Mode
							</a>
						</li>
					);
				} else {
					listItems.push(
						<li key="enterKidsMode" className="hamburger-menu-kids-logo">
							{/* TODO: Fix this the next time the file is edited. */}
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
							<a href="" onClick={onEnterKids}>
								<div className="kids-logo-image" />
							</a>
						</li>
					);
					const manageSubscription = (
						<li key="manageSubscription">
							<a href={manageSubscriptionUrl}>Manage subscription</a>
						</li>
					);
					listItems.push(manageSubscription);
				}
			}
			if (!isKidsMode) {
				const support = (
					<li key="support">
						<a href="https://support.faithlife.com/hc/en-us/categories/360000551632">Support</a>
					</li>
				);
				listItems.push(support);
			}

			const signOut = (
				<li key="signOut">
					<a href="/auth/signout" onClick={event => this.signOut(event)}>
						Sign out
					</a>
				</li>
			);
			listItems.push(signOut);
		} else if (!this.hideSignInLink(pathname)) {
			const signIn = (
				<li key="signIn">
					<Link to="/signin">Sign in</Link>
				</li>
			);
			listItems.push(signIn);
		}

		const categoriesListItems: JSX.Element[] = [];

		if (!isKidsMode) {
			let displayCategories = categories.defaultCategories;
			if (!categories.isFetching && categories.categories) {
				displayCategories = categories.categories;
			}

			if (displayCategories) {
				displayCategories.forEach(c => {
					categoriesListItems.push(
						// TODO: Fix this the next time the file is edited.
						// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
						<li key={`hamburger-menu-${c.id}`} onClick={this._handleClick}>
							<Link to={getCategoryLink(c)}>{c.title}</Link>
						</li>
					);
				});
			}
			if (categories.isFetching) {
				categoriesListItems.push(
					// TODO: Fix this the next time the file is edited.
					// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
					<li key="menu-processing" onClick={this._handleClick}>
						Loading&hellip;
					</li>
				);
			}
		}

		return (
			<div className="hamburger-menu">
				<Dropdown className="dropdown" ref={ref => (this.dropdown = ref)}>
					<DropdownTrigger className="dropdown-top" alt="Menu">
						&#9776;
					</DropdownTrigger>
					<DropdownContent className={`dropdown-content${isKidsMode ? ' kids' : ''}`}>
						{/* TODO: Fix this the next time the file is edited. */}
						{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
						<ul key="accountItems" onClick={this._handleClick}>
							{listItems}
						</ul>
						{!isKidsMode ? (
							<>
								<hr key="categoriesSeparator" />
								<ul className="categories">{categoriesListItems}</ul>
							</>
						) : (
							false
						)}
					</DropdownContent>
				</Dropdown>
				<form ref={ref => (this.signOutForm = ref)} style={{ display: 'none' }} action="/auth/signout" method="post" />
			</div>
		);
	}
}
