import {
	getGroupSettingsRecommendedTypes,
	addGroupSettingsRecommendedItemTypes,
	removeGroupSettingsRecommendedItemTypes,
	IActionDispatch,
} from './action-types';
import { ITvClient } from '../clients/tv';
import { IAdminGroup } from '../reducers/admin-reducers';
import { FaithlifeTVClients } from '../clients/clients';
import { IRootState } from '../reducers';
import { getCategories } from './categories-actions';

interface GroupCategory {
	id: string;
	title: string;
	token: string;
	itemIds?: readonly string[];
}

export function getGroupSettingsRecommended(itemId: string, reload = false) {
	return (dispatch: IActionDispatch, getState: () => IRootState) => {
		const { admin, groupSettingsRecommended } = getState();
		if (
			!groupSettingsRecommended.isProcessing &&
			(!groupSettingsRecommended.recommended || groupSettingsRecommended.recommended.itemId !== itemId || reload)
		) {
			const groups = (admin && admin.groups) || [];
			return dispatch(doGetGroupSettingsRecommended(groups, itemId));
		}

		return Promise.resolve(groupSettingsRecommended);
	};
}

export function addGroupRecommendedItem(dispatch: IActionDispatch, groupId: string, itemId: string) {
	return {
		types: addGroupSettingsRecommendedItemTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => doAddGroupRecommendedItemAsync(dispatch, tvClient, groupId, itemId),
	};
}

export function removeGroupRecommendedItem(dispatch: IActionDispatch, groupId: string, itemId: string) {
	return {
		types: removeGroupSettingsRecommendedItemTypes,
		promise: ({ tvClient }: FaithlifeTVClients) =>
			doRemoveGroupRecommendedItemAsync(dispatch, tvClient, groupId, itemId),
	};
}

function doGetGroupSettingsRecommended(groups: readonly IAdminGroup[], itemId: string) {
	return {
		types: getGroupSettingsRecommendedTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => doGetGroupSettingsRecommendedAsync(tvClient, groups, itemId),
	};
}

async function doGetGroupSettingsRecommendedAsync(tvClient: ITvClient, groups: readonly IAdminGroup[], itemId: string) {
	let recommendedGroups: IAdminGroup[] = [];

	if (groups && groups.length) {
		const groupInfos = await Promise.all<{
			groupId: number;
			categories: GroupCategory[];
			isValidItem: boolean;
		}>(
			groups.map(async group => {
				const [categories, validationResults] = await Promise.all([
					tvClient.getGroupCategories(group.groupId),
					tvClient.validateGroupItem(group.groupId, itemId),
				]);

				return {
					groupId: group.groupId,
					...categories,
					isValidItem: (validationResults.results.find(result => result.itemId === itemId) || {}).valid || false,
				};
			})
		);
		const groupInfosByGroupId = groupInfos.reduce(
			(result, groupInfo) => ({ ...result, [groupInfo.groupId]: groupInfo }),
			{}
		);

		recommendedGroups = groups.filter(group => {
			const groupInfo = groupInfosByGroupId[group.groupId];
			return groupInfo.isValidItem;
		});

		recommendedGroups.forEach(group => {
			const groupInfo = groupInfosByGroupId[group.groupId];
			const categories = groupInfo.categories.find(category => category.token === 'recommended') || { itemIds: [] };
			group.isRecommended = categories.itemIds.includes(itemId);
		});
	}

	return {
		recommendedGroups,
		itemId,
	};
}

async function doAddGroupRecommendedItemAsync(
	dispatch: IActionDispatch,
	tvClient: ITvClient,
	groupId: string,
	itemId: string
) {
	let addedCategory = false;
	const getCategoriesResult = await tvClient.getGroupCategories(groupId);
	let recommendedCategory =
		getCategoriesResult && getCategoriesResult.categories.find(category => category.token === 'recommended');

	if (!recommendedCategory) {
		recommendedCategory = await tvClient.createGroupCategory(groupId, {
			title: 'Recommended',
			token: 'recommended',
		});
		addedCategory = true;
	}

	const itemIds = (recommendedCategory.itemIds || []).includes(itemId)
		? recommendedCategory.itemIds
		: [...recommendedCategory.itemIds, itemId];
	await tvClient.updateGroupCategoryItemIds(groupId, recommendedCategory.id, itemIds);
	if (addedCategory) {
		dispatch(getCategories(true, false));
	}
}

async function doRemoveGroupRecommendedItemAsync(
	dispatch: IActionDispatch,
	tvClient: ITvClient,
	groupId: string,
	itemId: string
) {
	const getCategoriesResult = await tvClient.getGroupCategories(groupId);
	const recommendedCategory =
		getCategoriesResult && getCategoriesResult.categories.find(category => category.token === 'recommended');

	if (recommendedCategory) {
		const itemIds = (recommendedCategory.itemIds || []).filter(x => x !== itemId);
		if (!itemIds.length) {
			await tvClient.deleteGroupCategory(groupId, recommendedCategory.id);
			dispatch(getCategories(true, false));
		} else {
			await tvClient.updateGroupCategoryItemIds(groupId, recommendedCategory.id, itemIds);
		}
	}
}
