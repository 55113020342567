import { getMembershipTypes } from '../actions/action-types';
import { IReduxReducerStateActions } from '.';

export interface IMembershipState extends IReduxReducerStateActions {
	isFaithlifeTVFollower?: boolean;
	features?: object;
}

export function membership(state: IMembershipState = {}, action): IMembershipState {
	const { type, result, error } = action;

	switch (type) {
		case getMembershipTypes.processing:
			return {
				...state,
				isFetching: true,
				isFaithlifeTVFollower: undefined,
				features: {},
				error: null,
			};

		case getMembershipTypes.success:
			return {
				...state,
				isFetching: false,
				isFaithlifeTVFollower: !!result.faithlifeTv,
				error: null,
			};

		case getMembershipTypes.error:
			return {
				...state,
				isFetching: false,
				isFaithlifeTVFollower: undefined,
				features: {},
				error,
			};

		default:
			return state;
	}
}
