import logger from '../utils/logger';

const isKidsModeKey = 'isKidsMode';

export function setKidsMode(isKidsMode) {
	try {
		localStorage.setItem(isKidsModeKey, isKidsMode ? 'true' : 'false');
	} catch (err) {
		logger.error('failed to set isKidsMode in local storage');
	}
}
export function getKidsMode() {
	let kidsMode = false;
	try {
		kidsMode = localStorage.getItem(isKidsModeKey) === 'true';
	} catch (err) {
		logger.error('failed to access localstorage');
	}
	return kidsMode;
}
