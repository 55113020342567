import { IFetcher } from './fetcher';
import { getUtmParameters } from '../helpers/analytics-helper';

export type IAuthClient = ReturnType<typeof createAuthClient>;

export function createAuthClient({ fetchJson }: { fetchJson: IFetcher }) {
	return {
		signOut() {
			return fetchJson('signout', {
				method: 'POST',
			});
		},

		signIn(email: string, password: string) {
			return fetchJson('signin', {
				method: 'POST',
				body: { email, password },
			});
		},

		register(name: string, email: string, password: string, mailingListsOptions) {
			const analyticsParams = getUtmParameters();

			const analyticsParamsArr: string[] = [];
			if (analyticsParams) {
				for (const key in analyticsParams) {
					analyticsParamsArr.push(`${key}=${analyticsParams[key]}`);
				}
			}
			const analyticsParamsStr = analyticsParamsArr.join('&');

			return fetchJson(`register?${analyticsParamsStr || ''}`, {
				method: 'POST',
				body: { name, email, password, mailingListsOptions },
			});
		},
	};
}
