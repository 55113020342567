import { getProductsTypes } from './action-types';
import { IProductsClient } from '../clients/products';
import { FaithlifeTVClients } from '../clients/clients';

export function getProducts(productIds: readonly string[]) {
	return {
		types: getProductsTypes,
		promise: ({ productsClient }: FaithlifeTVClients) => doGetProducts(productsClient, productIds),
	};
}

async function doGetProducts(productsClient: IProductsClient, productIds: readonly string[]) {
	const response = await productsClient.getProducts(productIds);

	return { products: response.products };
}

export function quickbuy(productId: string, orderUrl: string) {
	// orderUrl looks like https://ordersapi.logos.com/v3/carts/quickbuy?storefront=lo&skuIds={PRODUCTID}
	const path = orderUrl.slice(orderUrl.indexOf('carts'));
	return {
		types: getProductsTypes,
		promise: ({ productsClient }: FaithlifeTVClients) => doQuickBuy(productsClient, productId, path),
	};
}

async function doQuickBuy(productsClient: IProductsClient, productId: string, path: string) {
	const response = await productsClient.quickbuy(path);

	return { productId, purchaseSuccess: response.purchaseSuccess };
}
