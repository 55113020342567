interface IAction {
	type: string;
}

interface IAsyncAction {
	types: IAsyncActionTypes;
	promise: (...any) => Promise<any>;
}

interface IActionFunc {
	(dispatch: IActionDispatch, getState: () => any): any;
}

export interface IActionDispatch {
	<T extends IAsyncAction | IActionFunc>(action: T): Promise<any>;
	<T extends IAction>(action: T): void;
	<T extends IAction | IAsyncAction | IActionFunc>(action: T): any;
}

export interface IReduxProps {
	dispatch: IActionDispatch;
}

interface IAsyncActionTypes {
	processing: string;
	success: string;
	error: string;
}

export const setReferrerType = 'SET_REFERRER';
export const filterByCategoryType = 'FILTER_BY_CATEGORY';
export const filterByKindType = 'FILTER_BY_KIND';
export const setKidsModeType = 'SET_KIDS_MODE';
export const clearPurchaseItemType = 'CLEAR_PURCHASE_ITEM';
export const clearCategoriesType = 'CLEAR_CATEGORIES';
export const initializeCategoriesType = 'INITIALIZE_CATEGORIES';
export const getCountryAbbreviationTypes = createAsyncActionTypes('GET_COUNTRY_ABBREVIATION');
export const forgotPasswordTypes = createAsyncActionTypes('FORGOT_PASSWORD');
export const getCurrentUserTypes = createAsyncActionTypes('GET_CURRENT_USER');
export const signInTypes = createAsyncActionTypes('SIGN_IN');
export const signOutTypes = createAsyncActionTypes('SIGN_OUT');
export const getCategoriesTypes = createAsyncActionTypes('GET_CATEGORIES');
export const getKidsCategoriesTypes = createAsyncActionTypes('GET_KIDS_CATEGORIES');
export const getDefaultCategoriesTypes = createAsyncActionTypes('GET_DEFAULT_CATEGORIES');
export const getCategoryTypes = createAsyncActionTypes('GET_CATEGORY');
export const getGroupTypes = createAsyncActionTypes('GET_GROUP');
export const getItemsBySearchQueryTypes = createAsyncActionTypes('GET_ITEMS_BY_SEARCH_QUERY');
export const getTopItemsInCategoriesTypes = createAsyncActionTypes('GET_TOP_ITEMS_IN_CATEGORIES');
export const getItemsInCategoryTypes = createAsyncActionTypes('GET_ITEMS_IN_CATEGORY');
export const getPlaylistsTypes = createAsyncActionTypes('GET_PLAYLISTS');
export const getGetItemsInPlaylistTypes = createAsyncActionTypes('GET_ITEMS_IN_PLAYLIST');
export const getItemTypes = createAsyncActionTypes('GET_ITEM');
export const refreshItemTypes = createAsyncActionTypes('REFRESH_ITEM');
export const getMediaItemTypes = createAsyncActionTypes('GET_MEDIA_ITEM');
export const refreshMediaItemTypes = createAsyncActionTypes('REFRESH_MEDIA_ITEM');
export const setItemProgressTypes = createAsyncActionTypes('SET_ITEM_PROGRESS');
export const getSubscriptionTypes = createAsyncActionTypes('GET_SUBSCRIPTION');
export const getProductsTypes = createAsyncActionTypes('GET_PRODUCTS');
export const rateItemTypes = createAsyncActionTypes('RATE_ITEM');
export const getMembershipTypes = createAsyncActionTypes('GET_MEMBERSHIP');
export const createGroupMembershipTypes = createAsyncActionTypes('CREATE_GROUP_MEMBERSHIP');
export const purchaseItemTypes = createAsyncActionTypes('PURCHASE_ITEM');
export const getContentManagementTypes = createAsyncActionTypes('GET_CONTENT_MANAGEMENT_PAGE');
export const getAdminGroupsTypes = createAsyncActionTypes('GET_ADMIN_GROUPS');
export const getGroupSettingsRecommendedTypes = createAsyncActionTypes('GET_GROUP_SETTINGS_RECOMMENDED');
export const addGroupSettingsRecommendedItemTypes = createAsyncActionTypes('ADD_GROUP_SETTINGS_RECOMMENDED_ITEM');
export const removeGroupSettingsRecommendedItemTypes = createAsyncActionTypes('REMOVE_GROUP_SETTINGS_RECOMMENDED_ITEM');
export const getGroupSettingsRestrictedTypes = createAsyncActionTypes('GET_GROUP_SETTINGS_RESTRICTED');
export const addGroupSettingsRestrictedItemTypes = createAsyncActionTypes('ADD_GROUP_SETTINGS_RESTRICTED_ITEM');
export const removeGroupSettingsRestrictedItemTypes = createAsyncActionTypes('REMOVE_GROUP_SETTINGS_RESTRICTED_ITEM');
export const getWatchlistTypes = createAsyncActionTypes('GET_WATCHLIST');
export const getFeaturedItemsTypes = createAsyncActionTypes('GET_FEATURED_ITEMS');

function createAsyncActionTypes(
	processing: string,
	success = `${processing}_SUCCESS`,
	error = `${processing}_ERROR`
): IAsyncActionTypes {
	return { processing, success, error };
}
