import { formatUrl } from '../utils/url';
import { IFetcher } from './fetcher';

const groupKinds = [
	{
		kind: 'general',
		displayName: 'General',
	},
	{
		kind: 'proclaim',
		displayName: 'Proclaim',
	},
	{
		kind: 'denomination',
		displayName: 'Denomination',
	},
	{
		kind: 'church',
		displayName: 'Church',
	},
	{
		kind: 'smallGroup',
		displayName: 'Small Group',
	},
	{
		kind: 'youthGroup',
		displayName: 'Youth Group',
	},
	{
		kind: 'worshipTeam',
		displayName: 'Worship Team',
	},
	{
		kind: 'presentationTeam',
		displayName: 'Presentation Team',
	},
	{
		kind: 'leadershipGroup',
		displayName: 'Leadership Group',
	},
	{
		kind: 'committee',
		displayName: 'Committee',
	},
	{
		kind: 'school',
		displayName: 'School',
	},
	{
		kind: 'class',
		displayName: 'Class',
	},
	{
		kind: 'organization',
		displayName: 'Organization',
	},
	{
		kind: 'ministryOrganization',
		displayName: 'Ministry Organization',
	},
	{
		kind: 'club',
		displayName: 'Club',
	},
	{
		kind: 'discussionTopic',
		displayName: 'Discusson Topic',
	},
	{
		kind: 'pericopes',
		displayName: 'Pericopes',
	},
	{
		kind: 'notablePerson',
		displayName: 'Notable Person',
	},
	{
		kind: 'author',
		displayName: 'Author',
	},
	{
		kind: 'pastor',
		displayName: 'Pastor',
	},
	{
		kind: 'band',
		displayName: 'Band',
	},
	{
		kind: 'bookReading',
		displayName: 'Book Reading',
	},
	{
		kind: 'bibleReading',
		displayName: 'Bible Reading',
	},
	{
		kind: 'sportsTeam',
		displayName: 'Sports Team',
	},
	{
		kind: 'specialInterest',
		displayName: 'Special Interest',
	},
	{
		kind: 'family',
		displayName: 'Family',
	},
	{
		kind: 'missionary',
		displayName: 'Missionary',
	},
	{
		kind: 'radioStation',
		displayName: 'Radio Station',
	},
	{
		kind: 'noet',
		displayName: 'Noet',
	},
	{
		kind: 'marriage',
		displayName: 'Marriage',
	},
	{
		kind: 'publisher',
		displayName: 'Publisher',
	},
];

export function createAccountsClient({ fetchJson }: { fetchJson: IFetcher }) {
	return {
		getAccount(accountId) {
			return fetchJson(`accounts/${encodeURIComponent(accountId)}`);
		},

		getCurrentUser() {
			return fetchJson('users/me').then(
				result =>
					result && {
						alias: result.alias && result.alias.value,
						avatarUrls: result.avatarUrls && result.avatarUrls.value,
						email: result.email && result.email.value,
						gender: result.gender && result.gender.value,
						isSolicitable: !!(result.isSolicitable && result.isSolicitable.value),
						id: result.id.toString(),
						name: result.name && result.name.value,
						token: result.profileToken && result.profileToken.value,
					}
			);
		},

		forgotPassword(email) {
			return fetchJson('forgotpassword', {
				method: 'POST',
				body: { email },
			});
		},

		getGroup(groupToken) {
			return fetchJson(`groups/${groupToken}`).then(
				result =>
					result && {
						...result,
						kindDisplayName: (
							groupKinds.find(groupKind => result.kind === groupKind.kind) || {
								kind: result.kind,
								displayName: result.kind,
							}
						).displayName,
					}
			);
		},

		getAdminGroups() {
			return fetchJson('admingroups').then(result => result?.groups);
		},

		getGroups({ ids, relationships }: { ids?: readonly string[]; relationships?: readonly string[] }) {
			const getGroupsPage = (
				ids?: readonly string[],
				relationship?: readonly string[],
				start?: number,
				count?: number
			): Promise<{ total: number; groups: object[] }> => {
				if ((!ids || !ids.length) && (!relationship || !relationship.length)) {
					return Promise.resolve({ total: 0, groups: [] });
				}

				const url = formatUrl('groups', {
					start,
					count,
					ids: ids ? ids.join(',') : undefined,
					relationship: relationship ? relationship.join(',') : undefined,
				});
				return fetchJson(url);
			};

			const pageSize = 100;

			return getGroupsPage(ids, relationships, 0, pageSize).then(({ total, groups }) => {
				if (groups.length < total) {
					const promises: Promise<{ total: number; groups: object[] }>[] = [];
					for (let i = pageSize; i <= total; i += pageSize) {
						promises.push(getGroupsPage(ids, relationships, i, pageSize));
					}

					return Promise.all(promises).then(pagedGroupsInfo => {
						const pagedGroups = pagedGroupsInfo
							.map(groupInfo => groupInfo.groups || [])
							.reduce((result, groups) => [...result, ...groups], []);

						groups.push(...pagedGroups);
						return groups;
					});
				}

				return groups;
			});
		},

		createGroupMembership(groupId, kind, verifyMembership = false) {
			const join = () =>
				fetchJson(`groups/${groupId}/join`, {
					method: 'POST',
					body: { Kind: kind },
				});
			return verifyMembership
				? fetchJson(`groups/${groupId}`).then(result =>
						result && result.relationship && result.relationship.membership && result.relationship.membership.kind
							? Promise.resolve(result.relationship.membership)
							: join()
				  )
				: join();
		},
	};
}

export type IAccountsClient = ReturnType<typeof createAccountsClient>;
