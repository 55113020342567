import { getItemsBySearchQueryTypes } from '../actions/action-types';
import { IItem } from './item-reducers';
import { IReduxReducerStateActions } from '.';

export interface ISearchState extends IReduxReducerStateActions {
	query?: string;
	isSearching?: boolean;
	isPaging?: boolean;
	items?: IItem[] | null;
	nextPage?: string;
}

export function search(state: ISearchState = {}, action): ISearchState {
	const { type, query, pageToken, result, error } = action;
	const isSearching = (query || '').trim().length > 0;
	const isPaging = !!pageToken;
	const items = isPaging ? state.items : null;

	switch (type) {
		case getItemsBySearchQueryTypes.processing:
			return {
				...state,
				isFetching: true,
				query,
				isSearching,
				isPaging,
				items,
				error: null,
			};
		case getItemsBySearchQueryTypes.success:
			return {
				...state,
				isFetching: false,
				query,
				isSearching,
				isPaging: false,
				items: (items || []).concat(result.items),
				nextPage: result.nextPage,
				error: null,
			};
		case getItemsBySearchQueryTypes.error:
			return {
				...state,
				isFetching: false,
				query,
				isSearching,
				isPaging: false,
				error,
			};
		default:
			return state;
	}
}
