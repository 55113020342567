import { combineReducers } from 'redux';
import * as accountsReducers from './accounts-reducers';
import * as adminReducers from './admin-reducers';
import * as authReducers from './auth-reducers';
import * as categoryReducers from './category-reducers';
import * as contentManagementReducers from './content-management-reducers';
import * as featureFlagsReducers from './feature-flags-reducers';
import * as filterReducers from './filter-reducers';
import * as groupMembershipReducers from './group-membership-reducers';
import * as groupSettingsRecommendedReducers from './group-settings-recommended-reducers';
import * as groupSettingsRestrictedReducers from './group-settings-restricted-reducers';
import * as homeReducers from './home-reducers';
import * as itemReducers from './item-reducers';
import * as locationServicesReducers from './location-services-reducers';
import * as mediaReducers from './media-reducers';
import * as membershipReducers from './membership-reducers';
import * as productsReducers from './products-reducers';
import * as purchaseReducers from './purchase-reducers';
import * as referrerReducers from './referrer-reducers';
import * as searchReducers from './search-reducers';
import * as subscriptionReducers from './subscription-reducers';
import * as topItemsReducers from './top-items-reducers';
import * as kidsModeReducers from './kids-mode-reducers';
import * as watchlistReducers from './watchlist-reducers';
import * as featuredItemsReducers from './featured-items-reducers';

export interface IReduxReducerStateActions {
	isProcessing?: boolean;
	isFetching?: boolean;
	error?: string | null;
	isError?: boolean;
	isLoaded?: boolean;
}

const reducers = {
	...accountsReducers,
	...adminReducers,
	...authReducers,
	...categoryReducers,
	...homeReducers,
	...itemReducers,
	...mediaReducers,
	...productsReducers,
	...subscriptionReducers,
	...membershipReducers,
	...topItemsReducers,
	...featureFlagsReducers,
	...filterReducers,
	...referrerReducers,
	...searchReducers,
	...groupMembershipReducers,
	...groupSettingsRecommendedReducers,
	...groupSettingsRestrictedReducers,
	...purchaseReducers,
	...contentManagementReducers,
	...kidsModeReducers,
	...locationServicesReducers,
	...watchlistReducers,
	...featuredItemsReducers,
	tvWebBaseUrl: state => state || null,
};
export const rootReducer = combineReducers(reducers);

export type IRootState = { [R in keyof typeof reducers]: ReturnType<typeof reducers[R]> };
