import { forgotPasswordTypes } from '../actions/action-types';
import { IReduxReducerStateActions } from '.';

export interface IForgotPasswordState extends IReduxReducerStateActions {
	forgotPasswordRequested?: boolean;
}

export function forgotPassword(state: IForgotPasswordState = {}, action): IForgotPasswordState {
	const { type, error } = action;

	switch (type) {
		case forgotPasswordTypes.processing:
			return {
				...state,
				forgotPasswordRequested: false,
				isProcessing: true,
				error: null,
			};

		case forgotPasswordTypes.success:
			return {
				...state,
				forgotPasswordRequested: true,
				isProcessing: false,
				error: null,
			};

		case forgotPasswordTypes.error:
			// TODO: Fix this the next time the file is edited.
			// eslint-disable-next-line no-case-declarations
			const { serverResponse }: any = error || {};
			// TODO: Fix this the next time the file is edited.
			// eslint-disable-next-line no-case-declarations
			const { error: errorMessage }: any = serverResponse || {};
			return {
				...state,
				forgotPasswordRequested: false,
				isProcessing: false,
				error: errorMessage,
			};

		default:
			return state;
	}
}
