import { featureFlagsType } from '../actions/feature-flags-types';

export interface IFeatureFlagsState {
	[x: string]: boolean;
}

export function featureFlags(state: IFeatureFlagsState = {}, action = {}): IFeatureFlagsState {
	const { type, name, value }: any = action;

	if (type === featureFlagsType) {
		return {
			...state,
			[name]: value,
		};
	}

	return state;
}
