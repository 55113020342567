import { getItemTypes, rateItemTypes, refreshItemTypes, IActionDispatch } from './action-types';
// import logger from '../utils/logger';
import externalPlayUrls from '../data/externalPlayUrls';
import { ITvClient } from '../clients/tv';
import { IItem } from '../reducers/item-reducers';
import { FaithlifeTVClients } from '../clients/clients';
import { IRootState } from '../reducers';

export function getItem(
	{ itemId, resourceId, mediaId }: { itemId?: string; resourceId?: string; mediaId?: string },
	{ reload = false } = {}
) {
	return (dispatch: IActionDispatch, getState: () => IRootState) => {
		const { item } = getState();

		if (itemId && item.item && item.item.id === itemId) {
			if (reload) {
				return dispatch(doGetItem(itemId, resourceId, mediaId));
			}
			return dispatch(doRefreshItem(itemId, item.item));
		}

		if (itemId || (resourceId && mediaId)) {
			return dispatch(doGetItem(itemId, resourceId, mediaId));
		}

		return Promise.resolve(item);
	};
}

function doGetItem(itemId: string | undefined, resourceId: string | undefined, mediaId: string | undefined) {
	return {
		itemId,
		types: getItemTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => doGetItemAsync(tvClient, itemId, resourceId, mediaId),
	};
}

async function doGetItemAsync(
	tvClient: ITvClient,
	itemId: string | undefined,
	resourceId: string | undefined,
	mediaId: string | undefined
) {
	const itemFields = [
		'id',
		'kind',
		'title',
		'categories',
		'covers',
		'createdDate',
		'description',
		'subItems',
		'currentSubItem',
		'parentId',
		'progress',
		'duration',
		'metadata',
		'trailers',
		'rating',
		'formats',
		'productInfo',
		'groupInfo',
	];

	let item;
	if (resourceId && mediaId) {
		const { items = [] } = await tvClient.getItemForResource(resourceId, mediaId, itemFields);
		item = items.shift();
		if (!item) {
			return null;
		}
	} else {
		item = await tvClient.getItem(itemId, itemFields);
		if (item.id !== itemId) {
			return null;
		}
	}

	const extraItemFields = [
		'id',
		'kind',
		'title',
		'description',
		'covers',
		'duration',
		'metadata',
		'progress',
		'formats',
	];
	const extraItemIds: string[] = [];
	if (item.subItems) {
		extraItemIds.push(...item.subItems);
	}
	if (item.trailers) {
		extraItemIds.push(...item.trailers.map(it => it.id));
	}
	if (item.parentId) {
		extraItemIds.push(item.parentId);
	}
	const extraItems = extraItemIds.length ? await tvClient.getItems(extraItemIds, extraItemFields) : [];

	if (item.subItems && item.subItems.length) {
		item.subItems = extraItems.items.filter(it => item.subItems.includes(it.id));
	}

	if (item.trailers && item.trailers.length) {
		const trailerIds = item.trailers.map(it => it.id);
		item.trailers = extraItems.items.filter(it => trailerIds.includes(it.id));
	}

	if (item.parentId) {
		item.parentItem = extraItems.items.find(it => it.id === item.parentId);
	}

	item.externalPlayUrl = externalPlayUrls[item.id];

	return item;
}

function doRefreshItem(itemId: string, item: IItem) {
	return {
		itemId,
		types: refreshItemTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => doRefreshItemAsync(tvClient, itemId, item),
	};
}

async function doRefreshItemAsync(tvClient: ITvClient, itemId: string, item: IItem): Promise<IItem> {
	const itemFields = ['progress', 'eventInfo', 'currentSubItem'];

	if (item.subItems && item.subItems.length) {
		const allUpdatedItems = await tvClient.getItems([...item.subItems.map(subItem => subItem.id), item.id], itemFields);

		const updatedParent = allUpdatedItems.items.find(x => x.id === item.id);
		item.currentSubItem = updatedParent.currentSubItem;

		const updatedSubItems = allUpdatedItems.items.filter(x => x.id !== item.id);
		for (let index = 0; index < item.subItems.length; index++) {
			item.subItems[index].progress = updatedSubItems[index].progress;
		}
	} else {
		const updatedItem = await tvClient.getItem(itemId, itemFields);

		item.progress = updatedItem.progress;
	}
	return item;
}

export function rateItem({
	itemId,
	rating,
	shareToFaithlife = false,
}: {
	itemId?: string;
	rating?: number;
	shareToFaithlife?: boolean;
}) {
	return {
		types: rateItemTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => doRateItemAsync(tvClient, itemId, rating, shareToFaithlife),
	};
}

async function doRateItemAsync(
	tvClient: ITvClient,
	itemId: string | undefined,
	rating: number | undefined,
	shareToFaithlife: boolean
) {
	return tvClient.rateItem(itemId, rating, shareToFaithlife);
}
