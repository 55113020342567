import {
	getGroupSettingsRestrictedTypes,
	addGroupSettingsRestrictedItemTypes,
	removeGroupSettingsRestrictedItemTypes,
	IActionDispatch,
} from './action-types';
import { ITvClient } from '../clients/tv';
import { IAdminGroup } from '../reducers/admin-reducers';
import { FaithlifeTVClients } from '../clients/clients';
import { IRootState } from '../reducers';

export function getGroupSettingsRestricted(itemId: string, reload = false) {
	return (dispatch: IActionDispatch, getState: () => IRootState) => {
		const { admin, groupSettingsRestricted } = getState();
		if (
			!groupSettingsRestricted.isProcessing &&
			(!groupSettingsRestricted.restricted || groupSettingsRestricted.restricted.itemId !== itemId || reload)
		) {
			const groups = (admin && admin.groups) || [];
			return dispatch(doGetGroupSettingsRestricted(groups, itemId));
		}

		return Promise.resolve(groupSettingsRestricted);
	};
}

export function addGroupRestrictedItem(groupId: string, itemId: string) {
	return {
		types: addGroupSettingsRestrictedItemTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => doAddGroupRestrictedItemAsync(tvClient, groupId, itemId),
	};
}

export function removeGroupRestrictedItem(groupId: string, itemId: string) {
	return {
		types: removeGroupSettingsRestrictedItemTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => doRemoveGroupRestrictedItemAsync(tvClient, groupId, itemId),
	};
}

function doGetGroupSettingsRestricted(groups: readonly IAdminGroup[], itemId: string) {
	return {
		types: getGroupSettingsRestrictedTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => doGetGroupSettingsRestrictedAsync(tvClient, groups, itemId),
	};
}

async function doGetGroupSettingsRestrictedAsync(tvClient: ITvClient, groups: readonly IAdminGroup[], itemId: string) {
	let restrictedGroups: IAdminGroup[] = [];
	if (groups && groups.length) {
		const groupIds = groups.map(group => group.groupId);
		const restrictedItems = await tvClient.getRestrictedItems(groupIds, itemId);

		const groupInfos = await Promise.all<{
			groupId: string;
			isRestrictedItem: boolean;
			isValidItem: boolean;
		}>(
			groups.map(async group => {
				const validationResults = await tvClient.validateGroupItem(group.groupId, itemId, true, true);
				const isRestrictedItem = restrictedItems.some(restrictedItem => restrictedItem.groupId === group.groupId);

				return {
					groupId: group.groupId,
					isRestrictedItem,
					isValidItem: (validationResults.results.find(result => result.itemId === itemId) || {}).valid || false,
				};
			})
		);

		const groupInfosByGroupId = groupInfos.reduce(
			(result, groupInfo) => ({ ...result, [groupInfo.groupId]: groupInfo }),
			{}
		);

		restrictedGroups = groups.filter(group => {
			const groupInfo = groupInfosByGroupId[group.groupId];
			return groupInfo && groupInfo.isValidItem;
		});

		restrictedGroups.map(group => {
			const groupInfo = groupInfosByGroupId[group.groupId];
			group.isRestricted = !groupInfo.isRestrictedItem;
		});
	}

	return {
		restrictedGroups,
		itemId,
	};
}

async function doAddGroupRestrictedItemAsync(tvClient: ITvClient, groupId: string, itemId: string) {
	await tvClient.addRestrictedItem(groupId, itemId);
}

async function doRemoveGroupRestrictedItemAsync(tvClient: ITvClient, groupId: string, itemId: string) {
	await tvClient.removeRestrictedItem(groupId, itemId);
}
