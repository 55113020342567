import React from 'react';
import './index.less';
import { IKidsModeState } from '../../reducers/kids-mode-reducers';

interface IKidsLogoProps {
	kidsMode: IKidsModeState;
	onEnterKids: () => void;
	className: string;
}

export class KidsLogo extends React.Component<IKidsLogoProps> {
	onEnterKidsClicked = () => {
		const { kidsMode, onEnterKids } = this.props;
		if (!kidsMode.isKidsMode) {
			onEnterKids();
		}
	};

	render() {
		const { isKidsMode } = this.props.kidsMode;

		return (
			<div className={`kids-logo ${isKidsMode ? 'kids-mode' : 'regular-mode'}`}>
				{/* TODO: Fix this the next time the file is edited. */}
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
				<div onClick={this.onEnterKidsClicked}>
					<div className="kids-logo-image" />
				</div>
			</div>
		);
	}
}
