import { IFetcher } from './fetcher';

export type ILocationServicesClient = ReturnType<typeof createLocationServicesClient>;

interface ILocation {
	name?: string;
	organization?: string;
	address1?: string;
	address2?: string;
	address3?: string;
	city?: string;
	cityKind?: string;
	suburb?: string;
	suburbKind?: string;
	stateAbbreviation?: string;
	stateId?: string;
	countryAbbreviation?: string;
	countryId?: string;
	postalCode?: string;
	postalCodeKind?: string;
	formattedAddress?: string;
	latitude?: number;
	longitude?: number;
}

export function createLocationServicesClient({ fetchJson }: { fetchJson: IFetcher }) {
	return {
		geoLocate(): Promise<ILocation> {
			return fetchJson('geolocate');
		},
	};
}
