import { getGroupTypes, createGroupMembershipTypes, IActionDispatch } from './action-types';
import { IAccountsClient } from '../clients/accounts';
import { FaithlifeTVClients } from '../clients/clients';
import { ITvClient } from '../clients/tv';
import { IRootState } from '../reducers';

export function getGroup(groupToken, { reload = false } = {}) {
	return (dispatch: IActionDispatch, getState: () => IRootState) => {
		const { group } = getState();
		const isSameGroup = group.group && group.group.token === groupToken;
		if (isSameGroup) {
			if (reload) {
				return dispatch(doGetGroup(groupToken));
			}
			return Promise.resolve(group);
		}
		if (groupToken) {
			return dispatch(doGetGroup(groupToken));
		}
		return Promise.resolve(group);
	};
}

export function createJoinGroupMembership(groupId, verifyMembership = false) {
	return {
		types: createGroupMembershipTypes,
		promise: ({ accountsClient }: FaithlifeTVClients) =>
			accountsClient.createGroupMembership(groupId, 'member', verifyMembership),
	};
}

export function createFollowGroupMembership(groupId, verifyMembership = false) {
	return {
		types: createGroupMembershipTypes,
		promise: ({ accountsClient }: FaithlifeTVClients) =>
			accountsClient.createGroupMembership(groupId, 'follower', verifyMembership),
	};
}

function doGetGroup(groupToken) {
	return {
		groupToken,
		types: getGroupTypes,
		promise: ({ accountsClient, tvClient }: FaithlifeTVClients) =>
			doGetGroupAsync(accountsClient, tvClient, groupToken),
	};
}

async function doGetGroupAsync(accountsClient: IAccountsClient, tvClient: ITvClient, groupToken: string) {
	const group = await accountsClient.getGroup(groupToken);
	if (group && group.groupId) {
		group.items = [];

		const subcategories = await tvClient.getCategoriesByOwner(group.groupId);
		group.subcategories = subcategories;

		let pageToken;
		const categoryId = `group:${group.groupId}`;
		do {
			let response;
			try {
				response = await tvClient.getItemsInCategory(categoryId, pageToken, true, true, 1000);
				if (response && response.items) {
					group.items.push(...response.items);
					group.productInfo = response.productInfo;
				}
				pageToken = response && response.nextPage;
			} catch (error) {
				pageToken = null;
			}
		} while (pageToken);
	}
	return group;
}
