const colors = {
	white: '#fff',
	transparent: '#00000000',
	black: '#000',
	gray: '#95908f',
};
const space = ['0px', '2px', '4px', '8px', '12px', '16px', '24px', '32px', '64px', '96px'];
const radii = ['0px', '3px', '6px'];
const borders = [0, '1px solid'];

export const theme = {
	space: space,
	radii: radii,
	borders: borders,
	colors: {
		button: {
			primaryForeground: colors.white,
			primaryForegroundDisabled: colors.transparent,
			primaryBackground: colors.black,
			primaryHover: colors.transparent,
			primaryActive: colors.transparent,
			primaryDisabled: colors.transparent,

			focusBorder: colors.transparent,
			focusShadow: colors.transparent,
		},
		dropdown: {
			background: colors.black,
			backgroundHover: colors.black,
			foreground: colors.white,
			separator: colors.gray,
			foregroundDisabled: colors.gray,
		},
		checkbox: {
			background: colors.transparent,
			primary: colors.white,
			border: colors.gray,
			disabledBackground: colors.gray,
			disabledBorder: colors.gray,
		},
	},
};
