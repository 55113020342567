import React from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CancellationTokenSource from '@faithlife/cancellation';
import { AccountWidget } from '../../components/account-widget';
import { HamburgerMenu } from '../../components/hamburger-menu';
import { CategorySelectorMenu } from '../../components/category-selector-menu';
import { SearchBox } from '../../components/search-box';
import { KidsLogo } from '../kids-logo';
import { ExitKids } from '../exit-kids';
import { getItemsBySearchQuery, setKidsMode, getCategories } from '../../actions';
import * as storageHelper from '../../helpers/storage-helper';
import { amplitudeLogEvent } from '../../helpers/amplitude-helper';
import logger from '../../utils/logger';
import './index.less';
import { IUser } from '../../reducers/auth-reducers';
import { ISubscriptionState } from '../../reducers/subscription-reducers';
import { IKidsModeState } from '../../reducers/kids-mode-reducers';
import { ICategoriesState } from '../../reducers/home-reducers';
import { ISearchState } from '../../reducers/search-reducers';
import { IReduxProps } from '../../actions/action-types';

interface INavBarProps extends IReduxProps {
	user: IUser;
	location: URL;
	subscription: ISubscriptionState;
	search: ISearchState;
	categories: ICategoriesState;
	kidsMode: IKidsModeState;
}

interface INavBarState {
	isSearchBoxCollapsed?: boolean;
}

export class NavBar extends React.Component<INavBarProps, INavBarState> {
	static contextTypes = {
		router: PropTypes.object.isRequired,
	};

	state: INavBarState = {
		isSearchBoxCollapsed: this.props.kidsMode.isKidsMode,
	};

	componentWillUnmount() {
		if (this._cts) {
			this._cts.cancel();
		}
	}

	componentDidUpdate(prevProps: INavBarProps) {
		const {
			kidsMode: { isKidsMode },
			search: { query },
		} = this.props;
		if (!isKidsMode && prevProps.kidsMode.isKidsMode && query) {
			this.onSearch(query);
		}
	}

	onSearch = (query: string) => {
		const {
			dispatch,
			location: { pathname },
			kidsMode: { isKidsMode },
		} = this.props;
		const { router } = this.context;

		if (this._cts) {
			this._cts.cancel();
		}
		this._cts = new CancellationTokenSource();

		const queryString = (query || '').trim();
		const locationState = queryString
			? {
					pathname: '/search',
					search: `?q=${encodeURIComponent(queryString).replace(/%20/g, '+')}`,
			  }
			: '/';
		if (pathname === '/search') {
			router.history.replace(locationState);
		} else {
			router.history.push(locationState);
		}

		dispatch(getItemsBySearchQuery(query, null, this._cts.token, isKidsMode));
	};

	onSearchCollapsed = (isSearchBoxCollapsed: boolean) => {
		this.setState({ isSearchBoxCollapsed });
	};

	onExitKids = () => {
		const {
			dispatch,
			location: { pathname },
		} = this.props;
		dispatch(setKidsMode(false));
		storageHelper.setKidsMode(false);
		this.amplitudeSetKidsMode(false);
		dispatch(getCategories(true, false)).catch(logger.error);
		if (pathname.startsWith('/categories/')) {
			// after exiting kids mode the subcategories don't exist, so go to parent kids category
			this.context.router.history.push('kids');
		}
	};

	onEnterKids = () => {
		const { dispatch } = this.props;
		dispatch(setKidsMode(true));
		storageHelper.setKidsMode(true);
		this.amplitudeSetKidsMode(true);
		dispatch(getCategories(true, true)).catch(logger.error);
		// go back to home page when kids mode is entered. we don't have kids-based permissions on items and pages.
		this.context.router.history.push('/');
	};

	amplitudeSetKidsMode(isKidsMode: boolean) {
		amplitudeLogEvent('Kids Mode', {
			Type: isKidsMode ? 'enter' : 'exit',
		});
	}

	_cts = new CancellationTokenSource();

	render() {
		const { kidsMode, user, location, subscription, search, categories } = this.props;
		const isKidsMode = kidsMode.isKidsMode;
		const { isSearchBoxCollapsed } = this.state;

		return (
			<div className={`nav-bar ${isKidsMode ? 'kids' : ''}`}>
				<Link to="/">
					<div className="logo" />
				</Link>
				{subscription.hasSubscription ? (
					<KidsLogo
						kidsMode={kidsMode}
						onEnterKids={this.onEnterKids}
						className={isSearchBoxCollapsed ? '' : 'small-hide'}
					/>
				) : (
					false
				)}
				<nav>
					{!isKidsMode ? <CategorySelectorMenu categories={categories} /> : false}
					<SearchBox
						searchText={location.pathname === '/search' ? search.query : ''}
						onChange={this.onSearch}
						kidsMode={kidsMode}
						onChangeCollapsed={this.onSearchCollapsed}
						collapsed={isSearchBoxCollapsed}
					/>
					{isKidsMode ? (
						<ExitKids onExitKids={this.onExitKids} />
					) : (
						<AccountWidget user={user} location={location} subscription={subscription} />
					)}
					<HamburgerMenu
						user={user}
						location={location}
						subscription={subscription}
						categories={categories}
						kidsMode={kidsMode}
						onEnterKids={this.onEnterKids}
						onExitKids={this.onExitKids}
					/>
				</nav>
			</div>
		);
	}
}
