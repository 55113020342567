import flatten from 'lodash.flatten';
import { IActionDispatch, getFeaturedItemsTypes } from './action-types';
import { ITvClient } from '../clients/tv';
import { FaithlifeTVClients } from '../clients/clients';
import { IRootState } from '../reducers';
import logger from '../utils/logger';
import { featuredItems } from '../data/featuredItems';

export function getFeaturedItems() {
	return (dispatch: IActionDispatch, getState: () => IRootState) => {
		const { featuredItems } = getState();

		if (featuredItems.isFetching) {
			Promise.resolve();
		}

		if (!featuredItems.items) {
			return dispatch(doGetFeaturedItems());
		}

		return Promise.resolve(featuredItems);
	};
}

function doGetFeaturedItems() {
	return {
		types: getFeaturedItemsTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => doGetFeaturedItemsAsync(tvClient),
	};
}

const mergeObjects = (...objs) =>
	[...objs].reduce(
		(acc, obj) =>
			Object.keys(obj).reduce((a, k) => {
				acc[k] = obj[k];
				return acc;
			}, {}),
		{}
	);

async function doGetFeaturedItemsAsync(tvClient: ITvClient) {
	const itemIds = featuredItems.map(featuredItem => featuredItem.id);
	const itemFields = ['id', 'title', 'description', 'trailers', 'rating'];

	const response = await tvClient.getItems(itemIds, itemFields);

	let items = response && response.items;
	if (!Array.isArray(items)) {
		logger.error(response, 'Error getting featured items');
		return { items: [] };
	}

	const trailerIds = flatten(items.map(x => x.trailers))
		.filter(x => !!x)
		.map(x => x.id);
	if (trailerIds.length) {
		let trailers: { items?: any[] } | undefined;
		try {
			trailers = await tvClient.getItems(trailerIds, ['formats']);
		} catch (err) {
			logger.error(err, 'Error getting trailers');
		}
		if (trailers && trailers.items) {
			for (const item of items) {
				if (item.trailers && item.trailers.length) {
					const itemTrailerIds = item.trailers.map(x => x.id);
					item.trailers = trailers.items.filter(x => itemTrailerIds.includes(x.id));
				} else {
					delete item.trailers;
				}
			}
		}
	}

	items = items.map(item => {
		return mergeObjects(
			item,
			featuredItems.find(featuredItem => featuredItem.id === item.id)
		);
	});

	return { items };
}
