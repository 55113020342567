import { getMediaItemTypes, refreshMediaItemTypes, setItemProgressTypes, IActionDispatch } from './action-types';
import { getItemAsync } from '../utils/item-utils';
import { IProgress } from '../reducers/item-reducers';
import { ITvClient } from '../clients/tv';
import { FaithlifeTVClients } from '../clients/clients';
import { IRootState } from '../reducers';

const kidsCategoryId = '504';

export function getMediaItem(
	{
		itemId,
		assetId,
		resourceId,
		mediaId,
		playlistId,
	}: { itemId?: string; assetId?: string; resourceId?: string; mediaId?: string; playlistId?: string },
	{ reload = false } = {}
) {
	return (dispatch: IActionDispatch, getState: () => IRootState) => {
		const { mediaItem } = getState();

		if (mediaItem.isFetching) {
			return Promise.resolve();
		}
		if (
			!reload &&
			mediaItem.item &&
			((itemId && mediaItem.item.id === itemId) || (assetId && mediaItem.item.assetId === assetId))
		) {
			return dispatch(doRefreshMediaItem(itemId, assetId, mediaItem));
		}
		if (itemId || assetId || (resourceId && mediaId)) {
			return dispatch(doGetMediaItem(itemId, assetId, resourceId, mediaId, playlistId));
		}
		return Promise.resolve();
	};
}

function doRefreshMediaItem(itemId, assetId, mediaItem) {
	return {
		itemId,
		types: refreshMediaItemTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => doRefreshMediaItemAsync(tvClient, itemId, assetId, mediaItem),
	};
}

async function doRefreshMediaItemAsync(tvClient: ITvClient, itemId: string, assetId: string, mediaItem) {
	const itemFields = ['progress', 'eventInfo'];
	let updatedItem;
	if (assetId) {
		const { items = [] } = (await tvClient.getItemForAsset(assetId, itemFields)) || {};
		updatedItem = items.shift();
	} else if (itemId) {
		updatedItem = await tvClient.getItem(itemId, itemFields);
	}
	if (updatedItem) {
		mediaItem.item.progress = updatedItem.progress;
		if (mediaItem.item?.eventInfo && updatedItem.eventInfo) {
			mediaItem.item.eventInfo.currentViewerCount = updatedItem.eventInfo.currentViewerCount;
		}
	}
	return mediaItem.item;
}

function doGetMediaItem(itemId, assetId, resourceId, mediaId, playlistId) {
	return {
		itemId,
		assetId,
		types: getMediaItemTypes,
		promise: ({ tvClient }: FaithlifeTVClients) =>
			getItemAsync(tvClient, itemId, assetId, resourceId, mediaId, playlistId),
	};
}

export function setItemProgress(itemId: string, progress: IProgress, isKids?: boolean) {
	const parentId = isKids ? kidsCategoryId : null;
	return {
		types: setItemProgressTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => tvClient.setItemProgress(itemId, progress, parentId),
	};
}
