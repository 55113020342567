import { getCountryAbbreviationTypes } from './action-types';
import { FaithlifeTVClients } from '../clients/clients';

export const getCountryAbbreviation = () => {
	return {
		types: getCountryAbbreviationTypes,
		promise: ({ locationServicesClient }: FaithlifeTVClients) =>
			locationServicesClient.geoLocate().then(result => result.countryAbbreviation),
	};
};
