import { setReferrerType } from '../actions/action-types';

export interface IReferrerState {
	referrer?: string;
}

export function referrer(state: IReferrerState = {}, action = {}): IReferrerState {
	const { type, referrer }: any = action;

	if (type === setReferrerType) {
		return {
			...state,
			referrer,
		};
	}

	return state;
}
