import { getMediaItemTypes, refreshMediaItemTypes } from '../actions/action-types';
import { IItem } from './item-reducers';
import { IReduxReducerStateActions } from '.';

export interface IMediaItemState extends IReduxReducerStateActions {
	item?: IItem | null;
}

export function mediaItem(state: IMediaItemState = {}, action): IMediaItemState {
	const { type, result, error } = action;

	switch (type) {
		case getMediaItemTypes.processing:
			return { ...state, isFetching: true, error: null };

		case getMediaItemTypes.success:
			return { ...state, isFetching: false, item: result, error: null };

		case getMediaItemTypes.error:
			return { ...state, isFetching: false, error };

		case refreshMediaItemTypes.success:
			if (state.item && state.item.id === result.id) {
				return { ...state, item: { ...state.item, ...result } };
			}
			return state;

		default:
			return state;
	}
}
