import React from 'react';
import './index.less';

interface IExitKidsProps {
	onExitKids: () => void;
}

interface IExitKidsState {
	isConfirming: boolean;
}

export class ExitKids extends React.Component<IExitKidsProps, IExitKidsState> {
	private _timeoutId: number | null = null;

	state: IExitKidsState = {
		isConfirming: false,
	};

	componentWillUnmount = () => {
		this.checkForTimeout();
	};

	onExitKidsClicked = () => {
		this.checkForTimeout();
		this.setState({ isConfirming: true });
	};

	onYesClicked = () => {
		this.checkForTimeout();
		this.setState({ isConfirming: false });
		this.props.onExitKids();
	};

	onNoClicked = () => {
		this.checkForTimeout();
		this.setState({ isConfirming: false });
	};

	onMouseOut = () => {
		if (!this._timeoutId) {
			this._timeoutId = setTimeout(() => {
				this.setState({ isConfirming: false });
				this._timeoutId = null;
			}, 3000) as any; // TODO: remove this once node types aren't used with the client side
		}
	};

	checkForTimeout() {
		if (this._timeoutId) {
			clearTimeout(this._timeoutId);
			this._timeoutId = null;
		}
	}

	render() {
		return (
			<div className="exit-kids">
				{this.state.isConfirming ? (
					// TODO: Fix this the next time the file is edited.
					// eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
					<div className="confirm-options" onMouseOut={this.onMouseOut}>
						{/* TODO: Fix this the next time the file is edited. */}
						{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
						<span onClick={this.onYesClicked}>Yes </span>/{/* TODO: Fix this the next time the file is edited. */}
						{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
						<span onClick={this.onNoClicked}> No</span>
					</div>
				) : (
					<button onClick={this.onExitKidsClicked}>Exit Kids</button>
				)}
			</div>
		);
	}
}
