// https://git/gist/ScottAlexander/1988fda48b80dc0d41cee6513ebbab30#file-get-utm-tracking-parameters-js
export function getUtmParameters() {
	const name = 'faithlife-utm-parameters';
	const nameWithEquals = `${name}=`;
	const tokens = document.cookie.split(';');
	for (let i = 0; i < tokens.length; i++) {
		let c = tokens[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1, c.length);
		}
		if (c.indexOf(nameWithEquals) === 0) {
			try {
				const value = c.substring(nameWithEquals.length, c.length);
				return JSON.parse(atob(value));
			} catch (e) {
				console.error(`Failure parsing ${name} cookie`);
			}
		}
	}

	return null;
}
