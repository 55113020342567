import { getSubscriptionTypes } from '../actions/action-types';
import { IReduxReducerStateActions } from '.';

export interface ISubscriptionState extends IReduxReducerStateActions {
	hasSubscription?: boolean;
	subscriptions?: string[];
}

const faithlifeTVSubscriptions = ['PLUS'];

export function subscription(state: ISubscriptionState = {}, action): ISubscriptionState {
	const { type, result, error } = action;

	switch (type) {
		case getSubscriptionTypes.processing:
			return {
				...state,
				isFetching: true,
				hasSubscription: undefined,
				subscriptions: undefined,
				error: null,
			};

		case getSubscriptionTypes.success: {
			const { subscriptions } = result;
			const hasSubscription = !!(
				Array.isArray(subscriptions) && subscriptions.some(x => faithlifeTVSubscriptions.includes(x))
			);
			return {
				...state,
				isFetching: false,
				hasSubscription,
				subscriptions,
				error: null,
			};
		}

		case getSubscriptionTypes.error:
			return {
				...state,
				isFetching: false,
				hasSubscription: undefined,
				subscriptions: undefined,
				error,
			};

		default:
			return state;
	}
}
