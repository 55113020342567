import { getCategoryTypes } from '../actions/action-types';
import { IItem } from './item-reducers';
import { IReduxReducerStateActions } from '.';

export interface ICover {
	url?: string;
	width?: number;
	height?: number;
}

export interface ICategory {
	items: IItem[];
	subcategories: ICategory[];
	nextPage: string;
	id: string;
	token: string;
	title: string;
	ownerId: string;
	productInfo: any;
	covers: ICover[];
}

export interface ICategoryState extends IReduxReducerStateActions {
	category?: ICategory | null;
	categoryId?: string;
	categoryToken?: string;
}

export function category(state: ICategoryState = {}, action): ICategoryState {
	const { type, result, error, categoryToken, categoryId } = action;

	switch (type) {
		case getCategoryTypes.processing:
			return { ...state, isFetching: true, categoryToken, categoryId, error: null };

		case getCategoryTypes.success:
			if (result) {
				return { ...state, isFetching: false, category: result, error: null };
			} else if (
				state.category &&
				(categoryToken ? state.category.token === categoryToken : categoryId && state.category.id === categoryId)
			) {
				return { ...state, isFetching: false, error: null };
			}
			return { ...state, isFetching: false };

		case getCategoryTypes.error:
			return { ...state, isFetching: false, error };

		default:
			return state;
	}
}
