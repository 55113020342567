import { getGroupTypes, createGroupMembershipTypes } from '../actions/action-types';
import { IProductInfo, IItem } from './item-reducers';
import { ICategory } from './category-reducers';
import { IReduxReducerStateActions } from '.';

export interface IGroup {
	name: string;
	groupId: number;
	token: string;
	kindDisplayName: string;
	city: string;
	stateAbbrev: string;
	memberCount: number;
	followerCount: number;
	avatarUrlLarge: string;
	items: IItem[];
	subcategories: ICategory[];
	availableActions: {
		relationship: string;
		signInAndJoin: boolean;
		signInAndFollow: boolean;
		join: boolean;
		follow: boolean;
	};
	relationship: {
		membership: {
			kind: string;
		};
	};
	productInfo: IProductInfo;
}

export interface IGroupState extends IReduxReducerStateActions {
	group?: IGroup | null;
	groupMembership?: IGroupMembershipState;
}

export interface IGroupMembershipState extends IReduxReducerStateActions {
	created?: boolean;
}

export function group(state: IGroupState = {}, action): IGroupState {
	const { type, result, error } = action;

	switch (type) {
		case getGroupTypes.processing:
			return { ...state, isFetching: true, group: null, error: null };

		case getGroupTypes.success:
			return { ...state, isFetching: false, group: result, error: null };

		case getGroupTypes.error:
			return { ...state, isFetching: false, error };

		default:
			return state;
	}
}

export function groupMembership(state: IGroupMembershipState = {}, action): IGroupMembershipState {
	const { type, error } = action;

	switch (type) {
		case createGroupMembershipTypes.processing:
			return { ...state, isProcessing: true, created: false, error: null };

		case createGroupMembershipTypes.success:
			return { ...state, isProcessing: false, created: true, error: null };

		case createGroupMembershipTypes.error:
			return { ...state, isProcessing: false, created: false, error };

		default:
			return state;
	}
}
