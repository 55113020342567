import { getFeaturedItemsTypes } from '../actions/action-types';
import { IReduxReducerStateActions } from '.';
import { IItem } from './item-reducers';

export interface IFeaturedItem extends IItem {
	heroImageUrl: string;
	promotionPath?: string;
}

export interface IFeaturedItemsState extends IReduxReducerStateActions {
	items?: IFeaturedItem[] | null;
}

export function featuredItems(state: IFeaturedItemsState = {}, action): IFeaturedItemsState {
	const { type, result, error } = action;

	switch (type) {
		case getFeaturedItemsTypes.processing:
			return { ...state, isFetching: true, items: null, error: null };

		case getFeaturedItemsTypes.success:
			return {
				...state,
				isFetching: false,
				items: result.items,
				error: null,
			};

		case getFeaturedItemsTypes.error:
			return { ...state, isFetching: false, error };

		default:
			return state;
	}
}
