import { setKidsModeType } from '../actions/action-types';

export interface IKidsModeState {
	isKidsMode?: boolean;
}

export function kidsMode(state: IKidsModeState = {}, action): IKidsModeState {
	const { type, isKidsMode } = action;

	switch (type) {
		case setKidsModeType:
			return { ...state, isKidsMode };

		default:
			return { ...state };
	}
}
