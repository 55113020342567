import orderBy from 'lodash.orderby';
import { getCoverUrl } from './item-utils';
import { ICategory, ICover } from '../reducers/category-reducers';

export function getCategoryLink(category: ICategory) {
	return category.id === 'watchlist'
		? '/categories/watchlist'
		: category.id === 'recent'
		? '/categories/recent'
		: category.id === 'store'
		? '/categories/store'
		: category.id.includes('group:') && category.token
		? `/groups/${category.token}`
		: category.ownerId
		? `/category/${category.id}`
		: `/categories/${category.token}`;
}

export function getCategoryCover({
	category,
	height,
	width,
	maxHeight,
	maxWidth,
}: {
	category?: ICategory;
	height?: number;
	width?: number;
	maxHeight?: number;
	maxWidth?: number;
}): ICover | undefined {
	if (!category) {
		return undefined;
	}
	if (height && width) {
		console.warn('both height and width set, ignoring width');
		width = undefined;
	}

	const covers = orderBy(category.covers, x => x.width * x.height, 'desc');
	let cover;
	if (height) {
		const largeCovers = covers.filter(x => height && x.height >= height);
		cover = largeCovers.filter(x => height && x.height <= 2 * height).pop() || largeCovers.pop() || covers.shift();
	} else if (width) {
		const largeCovers = covers.filter(x => width && x.width >= width);
		cover = largeCovers.filter(x => width && x.width <= 2 * width).pop() || largeCovers.pop() || covers.shift();
	} else {
		cover = covers.shift();
	}

	if (!cover) {
		console.warn(`no cover for item ${category.id}`);
		return undefined;
	}

	const aspectRatio = cover.width / cover.height;
	const coverUrl = getCoverUrl(cover);

	if (height && !width) {
		width = Math.round(height * aspectRatio);
	} else if (width && !height) {
		height = Math.round(width / aspectRatio);
	}

	if (width && maxWidth && width > maxWidth) {
		width = maxWidth;
		height = Math.round(maxWidth / aspectRatio);
	}

	if (height && maxHeight && height > maxHeight) {
		height = maxHeight;
		width = Math.round(maxHeight * aspectRatio);
	}

	return {
		url: coverUrl,
		width,
		height,
	};
}
