import { clearPurchaseItemType, purchaseItemTypes } from '../actions/action-types';
import { IReduxReducerStateActions } from '.';

export interface IPurchaseState extends IReduxReducerStateActions {
	purchaseSuccess?: boolean;
}

export function purchase(state: IPurchaseState = {}, action): IPurchaseState {
	const { type, error } = action;

	switch (type) {
		case purchaseItemTypes.processing:
			return {
				...state,
				isProcessing: true,
				purchaseSuccess: false,
				error: null,
			};
		case purchaseItemTypes.success:
			return {
				...state,
				isProcessing: false,
				purchaseSuccess: true,
				error: null,
			};
		case purchaseItemTypes.error:
			return {
				...state,
				isProcessing: false,
				purchaseSuccess: false,
				error: error.message,
			};
		case clearPurchaseItemType:
			return {
				...state,
				isProcessing: false,
				purchaseSuccess: false,
				error: null,
			};
		default:
			return state;
	}
}
