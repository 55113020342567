export function formatUrl(url: string, query: {}): string {
	const queryParams =
		query &&
		Object.keys(query)
			.map(key => [key, query[key]])
			.filter(([, value]) => value != null)
			.map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value))
			.join('&');
	return queryParams ? url + '?' + queryParams : url;
}

export function convertStringToBoolean(value: string): boolean {
	return !!(value === '1' || value === 'true');
}
