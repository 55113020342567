import { getWatchlistTypes } from '../actions/action-types';
import { IReduxReducerStateActions } from '.';

export interface IWatchlistState extends IReduxReducerStateActions {
	itemIds?: readonly string[] | null;
}

export function watchlist(state: IWatchlistState = {}, action): IWatchlistState {
	const { type, result, error } = action;

	switch (type) {
		case getWatchlistTypes.processing:
			return { ...state, isFetching: true, itemIds: null, error: null };

		case getWatchlistTypes.success:
			return { ...state, isFetching: false, itemIds: result.itemIds, error: null };

		case getWatchlistTypes.error:
			return { ...state, isFetching: false, error };

		default:
			return state;
	}
}
