import intersection from 'lodash.intersection';
import uniqBy from 'lodash.uniqby';
import { IItem } from '../reducers/item-reducers';
import { ITvClient } from '../clients/tv';
import { FaithlifeTVClients } from '../clients/clients';
import { getTopItemsInCategoriesTypes, IActionDispatch } from './action-types';
import { IRootState } from '../reducers';

export function getTopItemsInCategories(categoryIds?: readonly string[]) {
	return (dispatch: IActionDispatch, getState: () => IRootState) => {
		const { topItems } = getState();
		if (
			(topItems.items && topItems.categoryIds && arraysAreSame(topItems.categoryIds, categoryIds)) ||
			topItems.isFetching
		) {
			return Promise.resolve();
		}
		if (categoryIds) {
			return dispatch(doGetTopItemsInCategories(categoryIds));
		}
		return Promise.resolve(topItems);
	};
}

function doGetTopItemsInCategories(categoryIds) {
	return {
		categoryIds,
		types: getTopItemsInCategoriesTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => doGetTopItemsInCategoriesAsync(tvClient, categoryIds),
	};
}

async function doGetTopItemsInCategoriesAsync(tvClient: ITvClient, categoryIds: readonly string[]) {
	// TODO: sort and limit
	const items: IItem[] = [];
	for (const categoryId of categoryIds) {
		let pageToken;
		do {
			try {
				const response = await tvClient.getItemsInCategory(categoryId, pageToken, true, false, 1000);
				items.push(...response.items);
				pageToken = response.nextPage;
			} catch (err) {
				pageToken = null;
			}
		} while (pageToken);
	}
	const uniqueItems = uniqBy(items, it => it.id);
	return { categoryIds, items: uniqueItems };
}

function arraysAreSame(arr1, arr2) {
	return (
		Array.isArray(arr1) &&
		Array.isArray(arr2) &&
		arr1.length === arr2.length &&
		intersection(arr1, arr2).length === arr1.length
	);
}
