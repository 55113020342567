export function raygunClearUser() {
	if (typeof window === 'undefined' || !window) {
		return;
	}
	const raygun = window['rg4js'];

	if (raygun && typeof raygun === 'function') {
		raygun('setUser', '');
	}
}
