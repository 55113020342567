import { productPageCampaign } from '../utils/campaigns';

export const plusIndividual = {
	productId: '62003',
	url: `https://store.faithlifetv.com/subscribe/293${productPageCampaign}`,
};
export const plusGroup = {
	productId: '56561',
	url: 'https://store.faithlifetv.com/products/56561/faithlife-tv-plus-early-access',
};
export const plusDefault = plusIndividual;
export const manageSubscriptionUrl = 'https://store.faithlifetv.com/account/subscriptions';
export const mobileEdSubscriptionUrl = 'https://www.logos.com/product/56677/mobile-ed-subscription';
const products = [plusIndividual, plusGroup];
export default products;
