import { filterByKindType, filterByCategoryType } from '../actions/action-types';

interface IFilterState {
	selectedKind?: string;
	selectedCategory?: string;
}

export function filter(state: IFilterState = {}, action): IFilterState {
	const { type, selectedKind, selectedCategory } = action;

	switch (type) {
		case filterByKindType:
			return selectedCategory ? { ...state, selectedKind, selectedCategory } : { ...state, selectedKind };

		case filterByCategoryType:
			return { ...state, selectedCategory };

		default:
			return state;
	}
}
