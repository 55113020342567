import flatten from 'lodash.flatten';
import {
	forgotPasswordTypes,
	getCurrentUserTypes,
	getMembershipTypes,
	getSubscriptionTypes,
	signOutTypes,
	signInTypes,
	IActionDispatch,
} from './action-types';
import { featureFlags } from './feature-flags-types';
import { setFeatureFlags } from '.';
import { ISubscriptionState } from '../reducers/subscription-reducers';
import { IMembershipState } from '../reducers/membership-reducers';
import { IAuthState } from '../reducers/auth-reducers';
import { FaithlifeTVClients } from '../clients/clients';
import { IRootState } from '../reducers';

export interface IMailingListCheckbox {
	checked: boolean;
	defaultChecked: boolean;
	data: string;
}

interface IMailingListsData {
	email: string;
	isOptedIn: boolean;
	hasGivenExpressConsent: boolean;
	contactListIds?: readonly string[];
}

export function getCurrentUser() {
	return {
		types: getCurrentUserTypes,
		promise: ({ accountsClient }: FaithlifeTVClients) => accountsClient.getCurrentUser(),
	};
}

export function requestForgotPassword(email) {
	return {
		types: forgotPasswordTypes,
		promise: ({ accountsClient }: FaithlifeTVClients) => accountsClient.forgotPassword(email),
	};
}

export function getCurrentUserIfNecessary() {
	return (dispatch: IActionDispatch, getState: () => IRootState) => {
		const { auth }: { auth: IAuthState } = getState();
		if (!auth.isProcessing && !auth.isLoaded) {
			return dispatch(getCurrentUser());
		}
		return Promise.resolve(auth);
	};
}

export function signOut() {
	return {
		types: signOutTypes,
		promise: ({ authClient }: FaithlifeTVClients) => authClient.signOut(),
	};
}

export function signIn(email, password) {
	return {
		types: signInTypes,
		promise: ({ authClient }: FaithlifeTVClients) => authClient.signIn(email, password),
	};
}

export function register(name: string, email: string, password: string, checkboxes: IMailingListCheckbox[]) {
	return {
		types: signInTypes,
		promise: ({ authClient }: FaithlifeTVClients) =>
			authClient.register(name, email, password, getMailingListsOptions(email, checkboxes)),
	};
}

export function checkSubscription({ reload = false } = {}) {
	return (dispatch: IActionDispatch, getState: () => IRootState) => {
		const { subscription }: { subscription: ISubscriptionState } = getState();
		if ((subscription.hasSubscription === undefined && !subscription.isFetching) || reload) {
			return dispatch(doCheckSubscription());
		}
		return Promise.resolve(subscription);
	};
}

function doCheckSubscription() {
	return {
		types: getSubscriptionTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => tvClient.getSubscriptions(),
	};
}

const featureFlagsExist = flatten(Object.keys(featureFlags).map(key => featureFlags[key])).length !== 0;

export function checkMembership({ reload = false } = {}) {
	return (dispatch: IActionDispatch, getState: () => IRootState) => {
		const { membership }: { membership: IMembershipState } = getState();
		if (featureFlagsExist) {
			if ((membership.isFaithlifeTVFollower === undefined && !membership.isFetching) || reload) {
				return dispatch(doCheckMembership()).then(response => {
					if (response && response.result) {
						const featureFlagGroups = ['faithlifeTv', 'faithlifeTvTeam', 'faithlifeEmployees'];
						const allFeatureFlags = flatten(
							featureFlagGroups.map(group => (response.result[group] && featureFlags[group] ? featureFlags[group] : []))
						);
						for (const featureFlag of allFeatureFlags) {
							dispatch(setFeatureFlags(featureFlag, true));
						}
					}
				});
			}
		}
		return Promise.resolve(membership);
	};
}

function doCheckMembership() {
	return {
		types: getMembershipTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => tvClient.getMembership(),
	};
}

function getMailingListsOptions(email: string, checkboxes: IMailingListCheckbox[] = []): IMailingListsData | undefined {
	const defaultChecked = checkboxes.filter(x => x.defaultChecked);
	const checked = checkboxes.filter(x => x.checked);

	// add a record indicating they opted in to the checked boxes
	if (checked.length) {
		return {
			email,
			isOptedIn: true,
			hasGivenExpressConsent: true,
			contactListIds: checked.map(x => x.data),
		};
	}

	// if we pre-selected checkboxes and they de-selected them, add a record indicating they opted-out of everything
	if (defaultChecked.length) {
		return {
			email,
			isOptedIn: false,
			hasGivenExpressConsent: false,
			contactListIds: undefined,
		};
	}

	// don't add a record if they didn't opt out of anything
	return undefined;
}
