import { getWatchlistTypes, IActionDispatch } from './action-types';
import logger from '../utils/logger';
import { ITvClient } from '../clients/tv';
import { FaithlifeTVClients } from '../clients/clients';
import { IRootState } from '../reducers';

export function getWatchlist() {
	return (dispatch: IActionDispatch, getState: () => IRootState) => {
		const { watchlist, auth } = getState();

		if (watchlist.isFetching) {
			Promise.resolve();
		}

		if (!watchlist.itemIds && auth.user?.id && auth.user.id !== '-1') {
			return dispatch(doGetWatchlist());
		}

		return Promise.resolve(watchlist);
	};
}

function doGetWatchlist() {
	return {
		types: getWatchlistTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => doGetWatchlistAsync(tvClient),
	};
}

async function doGetWatchlistAsync(tvClient: ITvClient) {
	const response = await tvClient.getWatchlist();
	const itemIds = response && response.itemIds;

	if (!Array.isArray(itemIds)) {
		logger.error(response, 'Error getting watchlist itemIds');
	}

	return { itemIds };
}

async function doAddItemToWatchlist(tvClient: ITvClient, itemId: string) {
	return tvClient.addItemToWatchlist(itemId);
}

export function addItemToWatchlist(itemId: string) {
	return {
		types: getWatchlistTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => doAddItemToWatchlist(tvClient, itemId),
	};
}

async function doRemoveItemFromWatchlist(tvClient: ITvClient, itemId: string) {
	return tvClient.removeItemFromWatchlist(itemId);
}

export function removeItemFromWatchlist(itemId: string) {
	return {
		types: getWatchlistTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => doRemoveItemFromWatchlist(tvClient, itemId),
	};
}
