import { getCurrentUserTypes, signOutTypes, signInTypes } from '../actions/action-types';
import { IReduxReducerStateActions } from '.';

export interface IAuthState extends IReduxReducerStateActions {
	user: IUser;
	shouldExportSignIn?: boolean;
}

export interface IUser {
	id: string;
	email: string;
	alias: string;
	name: string;
	accountType: string;
	userType: {
		value: string;
	};
}

export function auth(
	state: IAuthState = { user: defaultUser, isProcessing: false, isLoaded: false, error: null },
	action
): IAuthState {
	const { type, result, error } = action;

	switch (type) {
		case getCurrentUserTypes.processing:
			return { ...state, isProcessing: true, isLoaded: false, error: null };

		case getCurrentUserTypes.success:
			return {
				...state,
				isProcessing: false,
				user: result,
				isLoaded: true,
				error: null,
			};

		case getCurrentUserTypes.error:
			return { ...state, isProcessing: false, error: error.message };

		case signInTypes.success:
			return { ...state, isProcessing: false, user: result, isLoaded: true, shouldExportSignIn: true, error: null };

		case signOutTypes.success:
			return { ...state, isProcessing: false, user: defaultUser, error: null };

		case signInTypes.processing:
			return { ...state, isProcessing: true, error: null };

		case signInTypes.error: {
			const serverResponse = error.serverResponse;
			return { ...state, isProcessing: false, error: (serverResponse && serverResponse.error) || error.message };
		}

		default:
			return state;
	}
}

const defaultUser = {
	accountType: 'user',
	id: '-1',
	email: '',
	alias: '',
	name: '',
	userType: { value: 'User' },
};
