import { getAdminGroupsTypes } from '../actions/action-types';
import { IReduxReducerStateActions } from '.';

export interface IAdminGroup {
	groupId: string;
	name: string;
	token: string;
	isRecommended: boolean;
	isRestricted: boolean;
}

export interface IAdminState extends IReduxReducerStateActions {
	groups?: IAdminGroup[];
}

export function admin(state: IAdminState = {}, action): IAdminState {
	const { type, result, error } = action;

	switch (type) {
		case getAdminGroupsTypes.processing:
			return { ...state, isProcessing: true, isLoaded: false, error: null };

		case getAdminGroupsTypes.success:
			return {
				...state,
				isProcessing: false,
				groups: result.map(({ groupId, name, token }) => ({ groupId: groupId.toString(), name, token })),
				isLoaded: true,
				error: null,
			};

		case getAdminGroupsTypes.error:
			return { ...state, isProcessing: false, error: error.message };

		default:
			return state;
	}
}
