import React from 'react';
import './close-button.less';

interface ICloseButtonProps {
	onClick: () => void;
	className?: string;
}

interface ICloseButtonState {
	mouseOver: boolean;
}

export class CloseButton extends React.Component<ICloseButtonProps, ICloseButtonState> {
	state: ICloseButtonState = {
		mouseOver: false,
	};

	onMouseOver = () => {
		this.setState({ mouseOver: true });
	};

	onMouseOut = () => {
		this.setState({ mouseOver: false });
	};

	render() {
		const { className, onClick } = this.props;

		return (
			// TODO: Fix this the next time the file is edited.
			// eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
			<button
				className={`close-button ${className}`}
				onClick={onClick}
				onMouseOver={this.onMouseOver}
				onMouseOut={this.onMouseOut}
			>
				<CloseButtonIcon mouseOver={this.state.mouseOver} />
			</button>
		);
	}
}

interface ICloseButtonIconProps {
	mouseOver?: boolean;
}

const CloseButtonIcon: React.SFC<ICloseButtonIconProps> = ({ mouseOver }) => (
	<svg
		width="14px"
		height="14px"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		fill={mouseOver ? '#FFFFFF' : '#AAAAAA'}
	>
		<path d="M3,3V4L6,7,3,10v1H4L7,8l3,3h1V10L8,7l3-3V3H10L7,6,4,3z" />
	</svg>
);
