import 'whatwg-fetch';

export function createFetcher({ baseUrl }: { baseUrl: string }) {
	return function fetchJson(input, { method, body }: { method?: string; body?: any } = {}) {
		const init: RequestInit = {
			method: method || 'GET',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			credentials: 'same-origin',
		};

		if (body) {
			init.body = JSON.stringify(body);
		}

		const request = new Request(baseUrl + input, init);
		request.headers.append('x-requested-with', 'fetch');

		return fetch(request)
			.then(acceptOnNoContent)
			.then(rejectOnError)
			.then(response => response.json());
	};
}

export function rejectOnError(response) {
	if (!response.ok) {
		const error = new Error('Unexpected server response.');
		return response
			.json()
			.catch(() => {
				throw error;
			})
			.then(serverResponse => {
				(error as any).serverResponse = serverResponse;
				throw error;
			});
	}

	return response;
}

export function acceptOnNoContent(response) {
	if (response.status === 204) {
		response.json = () => null;
	}
	return response;
}
