import { getItemsBySearchQueryTypes } from './action-types';
import { ITvClient } from '../clients/tv';
import { FaithlifeTVClients } from '../clients/clients';

const kidsCategoryId = '504';

export function getItemsBySearchQuery(query?: string, pageToken?, cancellationToken?, isKids = false) {
	const queryString = (query || '').trim();

	if (!queryString) {
		return {
			type: getItemsBySearchQueryTypes.success,
			query,
			pageToken,
			result: {},
		};
	}

	return {
		query,
		pageToken,
		types: getItemsBySearchQueryTypes,
		promise: ({ tvClient }: FaithlifeTVClients) =>
			doGetItemsBySearchQueryAsync(tvClient, queryString, pageToken, cancellationToken, isKids),
		canCancel: true,
		cancellationGroup: 'search',
	};
}

async function doGetItemsBySearchQueryAsync(
	tvClient: ITvClient,
	query: string,
	pageToken: string,
	cancellationToken,
	isKids = false
) {
	if (cancellationToken) {
		await new Promise(resolve => {
			setTimeout(resolve, 250);
		});
	}
	if (cancellationToken && cancellationToken.isCancellationRequested) {
		return { items: [] };
	}
	const categoryId = isKids ? kidsCategoryId : null;
	const response = await tvClient.getItemsBySearchQuery(query, pageToken, true, 100, categoryId);

	return { items: response.items, nextPage: response.nextPage };
}
