import orderBy from 'lodash.orderby';
import logger from '../utils/logger';
import { ICover } from '../reducers/category-reducers';
import { IItem } from '../reducers/item-reducers';
import { ITvClient } from '../clients/tv';

export function getItemCover({
	item,
	height,
	width,
	maxHeight,
	maxWidth,
}: {
	item: IItem;
	height?: number;
	width?: number;
	maxHeight?: number;
	maxWidth?: number;
}): ICover | undefined {
	if (!item) {
		return undefined;
	}

	if (height && width) {
		console.warn('both height and width set, ignoring width');
		width = undefined;
	}

	const covers = orderBy(item.covers, x => x.width * x.height, 'desc');
	let cover;
	if (height) {
		// TODO: Fix this the next time the file is edited.
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const largeCovers = covers.filter(x => x.height >= height!);
		// TODO: Fix this the next time the file is edited.
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		cover = largeCovers.filter(x => x.height <= 2 * height!).pop() || largeCovers.pop() || covers.shift();
	} else if (width) {
		// TODO: Fix this the next time the file is edited.
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const largeCovers = covers.filter(x => x.width >= width!);
		// TODO: Fix this the next time the file is edited.
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		cover = largeCovers.filter(x => x.width <= 2 * width!).pop() || largeCovers.pop() || covers.shift();
	} else {
		cover = covers.shift();
	}

	if (!cover) {
		console.warn(`no cover for item ${item.id}`);
		return undefined;
	}

	const aspectRatio = cover.width / cover.height;
	const coverUrl = getCoverUrl(cover);

	if (height && !width) {
		width = Math.round(height * aspectRatio);
	} else if (width && !height) {
		height = Math.round(width / aspectRatio);
	}

	if (width && maxWidth && width > maxWidth) {
		width = maxWidth;
		height = Math.round(maxWidth / aspectRatio);
	}
	if (height && maxHeight && height > maxHeight) {
		height = maxHeight;
		width = Math.round(maxHeight * aspectRatio);
	}

	return {
		url: coverUrl,
		width,
		height,
	};
}

export function getCoverUrl(cover?: ICover) {
	if (!cover || !cover.url) {
		return undefined;
	}

	return cover.url.replace(/^http:/, 'https:');
}

export async function getItemAsync(
	tvClient: ITvClient,
	itemId: string,
	assetId: string,
	resourceId: string,
	mediaId: string,
	playlistId: string
): Promise<IItem | null> {
	const itemFields = [
		'id',
		'kind',
		'title',
		'categories',
		'covers',
		'description',
		'duration',
		'progress',
		'subItems',
		'formats',
		'eventInfo',
		'parentId',
		'productInfo',
		'metadata',
	];
	const parentItemFields = ['id', 'kind', 'title', 'covers', 'productInfo', 'description'];
	let item;
	if (assetId) {
		const { items = [] } = (await tvClient.getItemForAsset(assetId, itemFields)) || {};
		item = items.shift();
		if (item && !item.assetId) {
			item.assetId = assetId;
		}
	} else if (resourceId && mediaId) {
		const { items = [] } = await tvClient.getItemForResource(resourceId, mediaId, itemFields);
		item = items.shift();
	} else {
		item = await getItemAndNextAsync(tvClient, itemId, playlistId, itemFields);
	}

	if (!item) {
		return null;
	}

	if (item.subItems && item.subItems.length) {
		item = await getItemAndNextAsync(tvClient, item.subItems[0], playlistId, itemFields);
	}

	if (item.parentId && item.parentId.length) {
		const parentItem = await tvClient.getItem(item.parentId, parentItemFields).catch(logger.error);
		if (parentItem) {
			item.parentItem = parentItem;
		}
	}

	return item;
}

async function getItemAndNextAsync(tvClient: ITvClient, itemId: string, playlistId: string, fields: readonly string[]) {
	const [item, nextItems] = await Promise.all([
		tvClient.getItem(itemId, fields),
		tvClient.getNextItems(itemId, {
			playlistId,
			fields: ['id', 'covers', 'title', 'licenseKind'],
			showUnlicensed: true,
			limit: 1,
		}),
	]);
	return { ...item, nextItems: nextItems.items };
}
