import React from 'react';
import buildParts from 'react-google-tag-manager/lib/build_parts';
import Helmet from 'react-helmet';

const gtmId = 'GTM-NLKP8L';

interface IGoogleTagManagerProps {
	userId?: string;
}

const dataLayerName = 'dataLayer';
const scriptId = 'react-google-tag-manager-gtm';

export class GoogleTagManager extends React.Component<IGoogleTagManagerProps> {
	componentDidMount() {
		if (!window[dataLayerName]) {
			const gtmScriptNode = document.getElementById(scriptId);
			if (gtmScriptNode && gtmScriptNode.textContent) {
				eval(gtmScriptNode.textContent); // eslint-disable-line no-eval
			}
		}
	}

	render() {
		const userId = this.props.userId;
		const gtm = buildParts({
			id: gtmId,
			dataLayerName,
			additionalEvents: userId ? { userId, isSignedIn: true } : { isSignedIn: false },
		});

		return (
			<>
				<Helmet>
					<script id={scriptId}>{gtm.script}</script>
				</Helmet>
				<noscript dangerouslySetInnerHTML={{ __html: gtm.iframe }} />
			</>
		);
	}
}
