import {
	getGroupSettingsRecommendedTypes,
	addGroupSettingsRecommendedItemTypes,
	removeGroupSettingsRecommendedItemTypes,
} from '../actions/action-types';
import { IReduxReducerStateActions } from '.';
import { IAdminGroup } from './admin-reducers';

export interface IGroupSettingsRecommendedState extends IReduxReducerStateActions {
	recommended?: { itemId: string; recommendedGroups: IAdminGroup[] };
}

export function groupSettingsRecommended(
	state: IGroupSettingsRecommendedState = {},
	action
): IGroupSettingsRecommendedState {
	const { type, result, error } = action;

	switch (type) {
		case getGroupSettingsRecommendedTypes.processing:
		case addGroupSettingsRecommendedItemTypes.processing:
		case removeGroupSettingsRecommendedItemTypes.processing:
			return { ...state, isProcessing: true, error: null };

		case getGroupSettingsRecommendedTypes.success:
			return {
				...state,
				isProcessing: false,
				recommended: result,
				error: null,
			};

		case addGroupSettingsRecommendedItemTypes.success:
		case removeGroupSettingsRecommendedItemTypes.success:
			return { ...state, isProcessing: false, error: null };

		case getGroupSettingsRecommendedTypes.error:
		case addGroupSettingsRecommendedItemTypes.error:
		case removeGroupSettingsRecommendedItemTypes.error:
			return { ...state, isProcessing: false, error: error.message };

		default:
			return state;
	}
}
