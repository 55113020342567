import React from 'react';
import './index.less';

export class LoadingIndicator extends React.Component {
	render() {
		return (
			<div className="loading-indicator">
				<img src="/public/images/loading.png" width="100%" height="100%" alt="Loading" />
			</div>
		);
	}
}
