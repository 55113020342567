import { getCountryAbbreviationTypes } from '../actions/action-types';
import { IReduxReducerStateActions } from '.';

export interface ISignInLocationState extends IReduxReducerStateActions {
	countryAbbreviation?: string | null;
}

export function signInLocation(state: ISignInLocationState = {}, action): ISignInLocationState {
	const { type, result } = action;

	switch (type) {
		case getCountryAbbreviationTypes.processing:
			return {
				...state,
				isFetching: true,
				isError: false,
				countryAbbreviation: null,
			};

		case getCountryAbbreviationTypes.success:
			return {
				...state,
				isFetching: false,
				isError: false,
				countryAbbreviation: result,
			};

		case getCountryAbbreviationTypes.error:
			return {
				...state,
				isFetching: false,
				isError: true,
				countryAbbreviation: null,
			};

		default:
			return state;
	}
}
