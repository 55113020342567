import { IFetcher } from './fetcher';

export type IProductsClient = ReturnType<typeof createProductsClient>;

export function createProductsClient({ fetchJson }: { fetchJson: IFetcher }) {
	return {
		getProducts(productIds: readonly string[]) {
			return fetchJson('getProducts', {
				method: 'POST',
				body: { productIds },
			});
		},

		quickbuy(orderUrl: string) {
			return fetchJson('quickbuy', {
				method: 'POST',
				body: { orderUrl },
			}).then(response => {
				if (!response.purchaseSuccess) {
					throw new Error('Failed to purchase product');
				}
				return response;
			});
		},
	};
}
