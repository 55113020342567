import { getPlaylistsTypes, getGetItemsInPlaylistTypes, IActionDispatch } from './action-types';
import logger from '../utils/logger';
import { ITvClient } from '../clients/tv';
import { FaithlifeTVClients } from '../clients/clients';
import { IRootState } from '../reducers';

const showTodayPlaylist = false;

export function getPlaylists({ reload }: { reload: boolean }) {
	return (dispatch: IActionDispatch, getState: () => IRootState) => {
		const { playlists } = getState();
		if ((!playlists.isProcessing && !playlists.playlists) || reload) {
			return dispatch(doGetPlaylists());
		}
		return Promise.resolve({
			result: playlists.playlists,
			type: getPlaylistsTypes.success,
		});
	};
}

function getTodayPlaylistId() {
	const now = new Date();
	const month = ('0' + (now.getMonth() + 1)).slice(-2);
	const day = ('0' + now.getDate()).slice(-2);
	return `today-${now.getFullYear()}-${month}-${day}`;
}

function doGetPlaylists() {
	return {
		types: getPlaylistsTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => doGetPlaylistsAsync(tvClient),
	};
}

function doGetPlaylistsAsync(tvClient: ITvClient) {
	const playlists = showTodayPlaylist ? [{ id: getTodayPlaylistId() }] : [];

	return Promise.all(
		playlists.map(async playlist => {
			try {
				const response = await tvClient.getPlaylist({
					playlistId: playlist.id,
					showUnlicensed: true,
				});
				const { title, items } = response;
				return { ...playlist, title, items };
			} catch (err) {
				logger.error(err);
				return playlist;
			}
		})
	);
}

export function getItemsInPlaylist(itemId, playlistId) {
	return {
		types: getGetItemsInPlaylistTypes,
		promise: ({ tvClient }: FaithlifeTVClients) => doGetItemsInPlaylistAsync(tvClient, itemId, playlistId),
	};
}

async function doGetItemsInPlaylistAsync(tvClient: ITvClient, itemId: string, playlistId: string) {
	const response = await tvClient.getNextItems(itemId, { playlistId, showUnlicensed: true });
	const { items } = response;
	return { playlistId, items, nextPage: true };
}
