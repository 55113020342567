import logger from '../utils/logger';

export function amplitudeLogEvent(eventName: string, eventProps?: any) {
	if (typeof window === 'undefined' || !window) {
		return;
	}
	const amplitude = window['amplitude'];
	if (amplitude && typeof amplitude.getInstance === 'function') {
		try {
			amplitude.getInstance().logEvent(eventName, eventProps);
		} catch (e) {
			logger.error(e);
		}
	} else {
		console.log('window.amplitude.getInstance is not a function, not logging event', eventName, eventProps);
	}
}

export function amplitudeIdentify(userPropertyName: string, userPropertyValue?: any) {
	if (typeof window === 'undefined' || !window) {
		return;
	}
	const amplitude = window['amplitude'];

	if (amplitude && typeof amplitude.getInstance === 'function') {
		try {
			const identify = new amplitude.Identify();
			if (userPropertyValue) {
				identify.set(userPropertyName, userPropertyValue);
			} else {
				identify.unset(userPropertyName);
			}
			amplitude.getInstance().identify(identify);
		} catch (e) {
			logger.error(e);
		}
	} else {
		console.log('window.amplitude.getInstance is not a function, not setting user properties');
	}
}

export function amplitudeSetUser(userId) {
	const setUser = () => {
		if (typeof window === 'undefined' || !window) {
			return;
		}
		const amplitude = window['amplitude'];
		if (amplitude && typeof amplitude.getInstance === 'function') {
			try {
				amplitude.getInstance().setUserId(userId);
			} catch (e) {
				logger.error(e);
			}
		} else {
			console.log('window.amplitude.getInstance is not a function, not setting user');
		}
	};

	if (document.readyState !== 'loading') {
		setUser();
	} else if (document.addEventListener) {
		// TODO: Fix this the next time the file is edited.
		// eslint-disable-next-line mozilla/balanced-listeners
		document.addEventListener('DOMContentLoaded', setUser);
	} else {
		document['attachEvent']('onreadystatechange', () => {
			if (document.readyState === 'complete') {
				setUser();
			}
		});
	}
}

export function amplitudeSetUserSubscriptions(subscriptions) {
	if (typeof window === 'undefined' || !window) {
		return;
	}
	const amplitude = window['amplitude'];
	if (amplitude && typeof amplitude.getInstance === 'function') {
		if (subscriptions && subscriptions.length) {
			try {
				amplitude.getInstance().setUserProperties({ Subscriptions: subscriptions.sort() });
			} catch (e) {
				logger.error(e);
			}
		}
	} else {
		console.log('window.amplitude.getInstance is not a function, not setting user subscriptions');
	}
}

export function amplitudeClearUser() {
	if (typeof window === 'undefined' || !window) {
		return;
	}
	const amplitude = window['amplitude'];
	if (amplitude && typeof amplitude.getInstance === 'function') {
		try {
			amplitude.getInstance().setUserId(null);
		} catch (e) {
			logger.error(e);
		}
	} else {
		console.log('window.amplitude.getInstance is not a function, not clearing user');
	}
}
