import React from 'react';
import * as PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import * as ga from 'react-ga';
import debounce from 'lodash.debounce';
import { connect } from '../../connect';
import { amplitudeSetUser, amplitudeSetUserSubscriptions, amplitudeClearUser } from '../../helpers/amplitude-helper';
import { raygunClearUser } from '../../helpers/raygun-helper';
import { appRoute, preLoad } from '../../routes';
import { checkMembership, checkSubscription, getCurrentUserIfNecessary, setKidsMode, setReferrer } from '../../actions';
import { IReduxProps } from '../../actions/action-types';
import { GoogleTagManager } from '../../components/google-tag-manager';
import logger from '../../utils/logger';
import './index.less';
import * as storageHelper from '../../helpers/storage-helper';
import { IAuthState } from '../../reducers/auth-reducers';
import { ISubscriptionState } from '../../reducers/subscription-reducers';
import { IKidsModeState } from '../../reducers/kids-mode-reducers';
import { theme } from './theme';
import { ThemeProvider } from '../../utils/theme-context';

interface IAppProps extends IReduxProps {
	auth: IAuthState;
	tvWebBaseUrl: string;
	subscription: ISubscriptionState;
	kidsMode: IKidsModeState;
	location: URL;
	referrer?: object;
}

interface IRaygunUser {
	identifier: string;
	email?: string;
}

const reportPageview = (location: URL) => {
	const { pathname, search } = location;
	ga.pageview(`${pathname}${search}`);
};

const reportSearchPageview = debounce(reportPageview, 1000);

function mapStateToProps({ auth, subscription, kidsMode, tvWebBaseUrl }) {
	return { auth, subscription, kidsMode, tvWebBaseUrl };
}

function raygunSetUser(user) {
	const setUser = () => {
		if (typeof window.rg4js === 'function') {
			try {
				window.rg4js('setUser', user);
			} catch (e) {
				logger.error(e);
			}
		} else {
			console.log('window.rg4js is not a function, not setting user');
		}
	};

	if (document.readyState !== 'loading') {
		setUser();
	} else if (document.addEventListener) {
		// TODO: Fix this the next time the file is edited.
		// eslint-disable-next-line mozilla/balanced-listeners
		document.addEventListener('DOMContentLoaded', setUser);
	} else {
		document.attachEvent('onreadystatechange', () => {
			if (document.readyState === 'complete') {
				setUser();
			}
		});
	}
}

@connect(mapStateToProps)
export default class App extends React.Component<IAppProps> {
	private unlisten?: () => void;

	static contextTypes = {
		store: PropTypes.object.isRequired,
		router: PropTypes.object.isRequired,
	};

	static activate(store) {
		return Promise.all([
			store.dispatch(getCurrentUserIfNecessary()),
			store.dispatch(checkSubscription()).catch(logger.error),
			store.dispatch(checkMembership()).catch(logger.error),
		]);
	}

	componentDidMount() {
		const {
			dispatch,
			auth: {
				user: { id: userId, email },
			},
			subscription: { subscriptions },
			kidsMode: { isKidsMode },
		} = this.props;

		// route transitions are in client/main.js
		this.unlisten = this.context.router.history.listen(this.handleRouterUpdate.bind(this));

		dispatch(setReferrer(window.document.referrer));

		if (userId !== '-1') {
			ga.set({ userId });
			ga.set({ dimension4: userId });
			if (subscriptions && subscriptions.length !== 0) {
				ga.set({ dimension5: subscriptions.sort().join(',') });
				amplitudeSetUserSubscriptions(subscriptions);
			}

			const raygunUser: IRaygunUser = { identifier: userId };
			if (email) {
				raygunUser.email = email;
			}
			raygunSetUser(raygunUser);
			amplitudeSetUser(userId);

			if (isKidsMode === undefined) {
				dispatch(setKidsMode(storageHelper.getKidsMode()));
			}
		}
	}

	componentWillUnmount() {
		if (this.unlisten) {
			this.unlisten();
		}
	}

	componentWillReceiveProps(nextProps: IAppProps) {
		const { auth, dispatch, subscription } = this.props;
		const { auth: nextAuth, subscription: nextSubscription } = nextProps;
		if (!nextAuth.isProcessing && nextAuth.user && nextAuth.user.id !== '-1' && nextAuth.user.id !== auth.user.id) {
			const userId = nextAuth.user.id;
			ga.set({ userId });
			ga.set({ dimension4: userId });
			raygunSetUser(nextAuth.user);
			amplitudeSetUser(nextAuth.user.id);
			dispatch(checkSubscription({ reload: true })).catch(logger.error);
		}

		if (subscription.isFetching && !nextSubscription.isFetching) {
			const { subscriptions } = nextSubscription;
			ga.set({
				dimension5: subscriptions && subscriptions.length ? subscriptions.sort().join(',') : undefined,
			});
			amplitudeSetUserSubscriptions(subscriptions);
		}
		if (nextProps.auth.error) {
			raygunClearUser();
			amplitudeClearUser();
		}
	}

	handleRouterUpdate() {
		const location: URL = this.context.router.history.location;
		// initial load tracked in componentDidMount
		const { pathname } = location;
		ga.set({ page: pathname });
		if (pathname === '/search') {
			reportSearchPageview(location);
		} else {
			reportPageview(location);
		}

		preLoad(pathname, this.context.store, location, undefined, true);
		const { hash } = window.location;
		if (hash) {
			// Push onto callback queue so it runs after the DOM is updated,
			// this is required when navigating from a different page so that
			// the element is rendered on the page before trying to getElementById.
			setTimeout(() => {
				const id = hash.substring(1);
				const element = document.getElementById(id);
				if (element) {
					element.scrollIntoView();
				}
			}, 0);
		}
	}

	render() {
		const {
			auth,
			location: { pathname, search },
			subscription,
			tvWebBaseUrl,
		} = this.props;
		const userId = auth && auth.user && auth.user.id !== '-1' ? auth.user.id : undefined;
		const canonicalUrl = tvWebBaseUrl && `${tvWebBaseUrl.replace(/\/$/, '')}${pathname}${search}`;
		const classes = [
			'app-root',
			userId ? 'authenticated' : 'unauthenticated',
			subscription && subscription.hasSubscription ? 'has-subscription' : 'no-subscription',
		];

		return (
			<React.Fragment>
				<ThemeProvider value={theme}>
					<Helmet>{canonicalUrl && <link rel="canonical" href={canonicalUrl} />}</Helmet>
					<div style={{ height: '100%' }} className={classes.filter(x => !!x).join(' ')}>
						<Switch>
							{appRoute.routes?.map((route, index) => (
								<Route key={index} {...route} />
							))}
						</Switch>
					</div>
					<GoogleTagManager userId={userId} />
				</ThemeProvider>
			</React.Fragment>
		);
	}
}
