import React from 'react';
import './index.less';
import plusIcon from './plus-icon.svg';

interface IPlusRibbonProps {
	type?: 'compact' | 'item';
}

export class PlusRibbon extends React.Component<IPlusRibbonProps> {
	render() {
		return <img className={`plus-ribbon ${this.props.type ?? ''}`} src={plusIcon} alt="Faithlife TV+" />;
	}
}
