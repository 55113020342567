import { getItemTypes, refreshItemTypes } from '../actions/action-types';
import { ICover } from './category-reducers';
import { IReduxReducerStateActions } from '.';

export interface IFormat {
	type: string;
	url: string;
}

export interface IRating {
	average?: number;
	ratingCount?: number;
	userRating?: number;
}

export interface IGroupInfo {
	membershipKind: string;
	token: string;
	name: string;
	action: string;
	groupId: string;
	groupRestrictedLicense: string;
	hasGiving: boolean;
	groupGivingToken: string;
}

export interface IProgress {
	value?: number;
	kind?: string;
	playId?: string;
	startTime?: number;
	length?: number;
	bitrate?: string;
}

type PeriodKind = 'None' | 'Day' | 'Month' | 'Year';

export interface IProductInfo {
	productId?: string;
	skuId?: string;
	storefront?: string;
	productToken?: string;
	subscriptionPeriodKind: PeriodKind;

	quickBuy?: {
		skuId: string;
		productToken: string;
		storefront: string;
		displayAmount: string;
		usDollarDisplayAmount: string;
		subscriptionPeriodKind: PeriodKind;
	};

	addToCart?: {
		uri: string;
		displayAmount?: string;
		usDollarDisplayAmount?: string;
		subscriptionPeriodKind?: PeriodKind;
	};
}

export interface IMetadata {
	kind?: string;
	content?: string;
	property?: string;
	name?: string;
	data?: {
		actors: readonly string[];
		learningObjectives: readonly string[];
		mediaId: string;
		sectionTitle: string;
		segmentNumber: string;
	};
	ads?: any;
	liveStream?: {
		provider: string;
		playerSettings?: { [name: string]: any };
	};
}

export interface IEventInfo {
	isOnline: boolean;
	refreshInterval: number;
	currentViewerCount: number;
}

export interface IParentItem {
	productInfo: IProductInfo;
	description: string;
	covers: ICover[];
	id: string;
	kind: string;
	title: string;
}

export interface ITrailer {
	covers: ICover[];
	formats: IFormat[];
	type: string;
	id: string;
}

export interface ISubitem {
	covers: ICover[];
	duration: number;
	id: string;
	kind: string;
	metadata: IMetadata;
	progress?: IProgress;
	title: string;
}

export interface IItem {
	title: string;
	id: string;
	kind?: string;
	description?: string;
	groupInfo?: IGroupInfo;
	licenseKind?: string;
	categories?: readonly string[];
	currentSubItem?: string;
	subItems?: ISubitem[];
	covers: ICover[];
	progress?: IProgress;
	duration?: number;
	trailers?: ITrailer[];
	rating?: IRating;
	parentItem?: IParentItem;
	parentId?: string;
	formats?: IFormat[];
	productInfo?: IProductInfo;
	productLink?: string;
	metadata?: IMetadata;
	promotion?: string;
	createdDate?: string;
	externalPlayUrl?: string;
	eventInfo?: IEventInfo;
	error?: string;
	nextItems?: IItem[];
	delay?: number;
	itemId?: string;
	isPlaylistCover?: boolean;
	assetId?: string;
}

export interface IItemState extends IReduxReducerStateActions {
	item?: IItem;
}

export function item(state: IItemState = {}, action): IItemState {
	const { type, result, error } = action;

	switch (type) {
		case getItemTypes.processing:
			return { ...state, isFetching: true, item: state.item, error: null };

		case getItemTypes.success:
			return { ...state, isFetching: false, item: result, error: null };

		case getItemTypes.error:
			return { ...state, isFetching: false, error };

		case refreshItemTypes.success:
			if (state.item && state.item.id === result.id) {
				return { ...state, item: { ...state.item, ...result } };
			}
			return state;

		default:
			return state;
	}
}
