import { getProductsTypes } from '../actions/action-types';
import { productPageCampaign } from '../utils/campaigns';
import { IReduxReducerStateActions } from '.';

export interface IProduct {
	productId: string;
	ownsProduct: boolean;
	purchaseUrl?: string;
	successUrl?: string;
	price?: string;
	subscriptionPeriodKind?: string;
	cartUrl?: string;
	storeUrl?: string;
}
export interface IProductsState extends IReduxReducerStateActions {
	products?: IProduct[];
	canPurchase?: boolean;
	attemptedPurchase?: boolean;
	purchaseSuccess?: boolean;
	successUrl?: string;
	failUrl?: string;
}

const defaultStoreUrl = 'https://store.faithlifetv.com';

export function products(state: IProductsState = {}, action): IProductsState {
	const { type, result, error } = action;

	switch (type) {
		case getProductsTypes.processing:
			return { ...state, isProcessing: true, error: null };

		case getProductsTypes.success: {
			const isProcessing = false;

			// purchase result
			if (result.productId) {
				const product = state.products && state.products.find(x => x.productId === result.productId);

				if (product && result.purchaseSuccess) {
					product.ownsProduct = true;
					return {
						...state,
						isProcessing,
						attemptedPurchase: true,
						purchaseSuccess: true,
						successUrl: product.successUrl,
					};
				}
				return {
					...state,
					isProcessing,
					attemptedPurchase: true,
					purchaseSuccess: false,
					failUrl: product && product.cartUrl,
				};
			}

			// check products
			const resultProducts = result.products;
			const products: IProduct[] = [];
			let canPurchase = false;

			for (const product of resultProducts) {
				const productId = product.id;
				let ownsProduct = false;
				let purchaseUrl;
				let successUrl;
				let price;
				let subscriptionPeriodKind;
				let cartUrl;

				const storeUrl = product.storefrontAttributes.length
					? product.storefrontAttributes[0].productPageUri.concat(productPageCampaign)
					: defaultStoreUrl;

				if (product.skus.length) {
					const skus = product.skus[0];
					price =
						skus.priceDetails && skus.priceDetails.details.length
							? skus.priceDetails.details[0].preferredCurrency.displayAmount
							: null;
					subscriptionPeriodKind = skus.subscriptionInfo ? skus.subscriptionInfo.periodKind : null;

					if (product.storefrontAttributes.length) {
						if (
							skus.storefrontAttributes[0].errors &&
							skus.storefrontAttributes[0].errors[0].kind === 'userOwnsProduct'
						) {
							ownsProduct = true;
						}

						cartUrl = skus.storefrontAttributes[0].addToCart ? skus.storefrontAttributes[0].addToCart.uri : null;
						purchaseUrl = skus.storefrontAttributes[0].quickBuy ? skus.storefrontAttributes[0].quickBuy.uri : null;
						canPurchase = !!purchaseUrl || canPurchase;
					}
				}

				const metadata = product.metadata && product.metadata.find(x => x.key === 'faithlifeTvUrl' && x.text);
				if (metadata) {
					successUrl = metadata.text.text;
				}

				products.push({
					productId,
					ownsProduct,
					price,
					storeUrl,
					cartUrl,
					purchaseUrl,
					subscriptionPeriodKind,
					successUrl,
				});
			}

			return { ...state, isProcessing, canPurchase, products };
		}
		case getProductsTypes.error:
			console.error(error.message);
			return { ...state, isProcessing: false, error: error.message };

		default:
			return state;
	}
}
